import styled from '@emotion/styled'

import styles from './Banner.module.scss'
import Button from '../../../input/Button'
import Heading from '../../../typography/Heading'
import overridable from '../../../utils/overridable'
import SubNavBanner, { Props as BaseBannerProps } from '../SubNav/Banner'

export interface BannerProps extends BaseBannerProps {
    text?: string
    btnLabel?: string
}

const StyledHeading = styled(Heading)`
    white-space: normal;
`

const Banner = ({
    imageUrl,
    to,
    text,
    btnLabel,
    ...otherProps
}: BannerProps) => (
    <SubNavBanner {...otherProps} imageUrl={imageUrl} to={to}>
        {text && (
            <StyledHeading element="span" color="light" variant="h2">
                {text}
            </StyledHeading>
        )}
        {btnLabel && (
            <Button
                wide
                variant="primary"
                className={styles.bannerButton}
                name={btnLabel}
                category="subNav.banner"
            >
                {btnLabel}
            </Button>
        )}
    </SubNavBanner>
)

export default overridable('Banner')(Banner)
