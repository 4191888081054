import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { Form, useFieldState } from 'informed'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { minWidth } from '@emico/styles'

import { useIsEmailAvailable } from './account/IsEmailAvailable'
import { LoginError } from './AccountAccessForm'
import { useSetGuestEmailAddress } from './cart/CartPage/useCartItems'
import BackIcon from './core/BackIcon'
import Button from './input/Button'
import Checkbox from './input/CheckBox/Informed'
import { Email } from './input/Email'
import FormGroup from './input/FormGroup'
import { Label } from './input/Label'
import { Password } from './input/Password'
import Submit from './input/Submit'
import FeedbackMessage from './input/SuccessMessage'
import { Text as TextInput } from './input/Text'
import Icon from './media/Icon'
import Link from './navigation/Link'
import paths from './paths'
import PrivacyPolicyLink from './PrivacyPolicyLink'
import RewardsLink from './RewardsLink'
import TermsLink from './TermsLink'
import theme from './theme'
import Heading from './typography/Heading'
import Text from './typography/Text'
import { useLoginForm } from './useLoginForm'
import formInteraction from './utils/googleTagManager/formInteraction'
import push from './utils/googleTagManager/push'
import validateRequired from './utils/validateRequired'

interface CheckoutFormValues {
    username: string
    firstName?: string
    lastName?: string
    password?: string
    gdprChecked: boolean
}

const StyledBackIcon = styled(BackIcon)`
    margin-right: 1em;
`

const StyledHeading = styled(Heading)`
    margin-bottom: 0.5em;
`

const BackButton = styled(Button)`
    position: absolute;
    top: 13px;
    left: 0;

    @media ${minWidth('lg')} {
        top: 33px;
    }
`

const ContinueButton = styled(Button)`
    margin-top: 1em;
`

const ForgotPasswordLink = styled(Link)`
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;

    &,
    &:visited,
    &:link,
    &:hover,
    &:active {
        color: ${theme.colors.muted};
        text-decoration: underline;
    }
`
const StyledCheckBox = styled(Checkbox)`
    & input {
        width: 0;
    }
`

const Intro = styled(Text)`
    margin-bottom: 2em;
`

const ContinueAsGuestButton = ({
    handleLoading,
    handleError,
}: {
    handleLoading(loading: boolean): void
    handleError(error?: string): void
}) => {
    const history = useHistory()
    const { value: email } = useFieldState('username')

    const setGuestEmailAddress = useSetGuestEmailAddress()

    const handleContinue = async () => {
        handleLoading(true)
        handleError(undefined)

        await setGuestEmailAddress(`${email}`)
        push(
            formInteraction({
                formName: 'Checkout',
                formID: 'checkout',
                formStep: '0',
                formStepName: 'Continue as guest',
            }),
        )
        handleLoading(false)
        history.push(paths.checkoutDelivery)
    }

    return (
        <ContinueButton
            variant="secondary"
            wide
            name="Continue without account"
            category="checkout.login.continue"
            onClick={handleContinue}
        >
            <Trans id="checkout.login.continueWithoutAccount">
                Continue without account
            </Trans>
        </ContinueButton>
    )
}

const CheckoutAccessForm = () => {
    const [guestLoading, setGuestLoading] = useState<boolean>(false)
    const [guestError, setGuestError] = useState<string | undefined>(undefined)
    const [dataEntered, setDataEntered] = useState<{
        email: string | undefined
        firstName: string | undefined
        lastName: string | undefined
    }>()

    const {
        data: isEmailAvailable,
        loading: checkingEmail,
        refetch,
    } = useIsEmailAvailable(dataEntered?.email ?? '', {
        skip: !dataEntered?.email,
    })

    const {
        handleSubmit: loginOrCreate,
        error: loginError,
        isLoading: loginLoading,
    } = useLoginForm({
        redirect: paths.checkoutDelivery,
    })

    const error = guestError ?? loginError
    const isLoading = guestLoading ?? loginLoading

    const handleSubmit = async ({
        username,
        password,
        firstName,
        lastName,
    }: CheckoutFormValues) => {
        setDataEntered({ email: username, firstName, lastName })

        const isInvalidLogin =
            !password || !username || isEmailAvailable === undefined

        const isInvalidCreateAccount =
            isEmailAvailable === true && (!firstName || !lastName)

        if (isInvalidLogin || isInvalidCreateAccount) {
            window.scrollTo(0, 0)
            return
        }

        await loginOrCreate({
            username,
            password,
            customer:
                isEmailAvailable === true
                    ? {
                          firstname: firstName ?? '',
                          lastname: lastName ?? '',
                          dob: '',
                      }
                    : undefined,
        })
        refetch()
    }

    const customerExists = Boolean(dataEntered && isEmailAvailable === false)
    const customerDoesntExists = Boolean(
        dataEntered && isEmailAvailable === true,
    )

    const termsLink = <TermsLink />
    const rewardsLink = <RewardsLink />
    const privacyPolicyLink = <PrivacyPolicyLink />

    return (
        <>
            <StyledHeading variant="h2" element="h1">
                <Trans id="checkout.login.headingDefault">
                    I’d like to place an order
                </Trans>
            </StyledHeading>

            {customerExists ? (
                <Intro>
                    <Trans id="checkout.login.introLogin">
                        Fill in your password to log in.
                    </Trans>
                </Intro>
            ) : customerDoesntExists ? (
                <Intro>
                    <Trans id="checkout.login.introCreateAccount">
                        Enter your details to create an account instantly.
                    </Trans>
                </Intro>
            ) : (
                <Intro>
                    <Trans id="checkout.login.introDefault">
                        Enter your email address to log in or create a new
                        account.
                    </Trans>
                </Intro>
            )}

            <Form
                onSubmit={handleSubmit}
                noValidate
                data-cy="loginform"
                initialValues={{
                    username: '',
                    firstName: '',
                    lastName: '',
                    password: '',
                    gdprChecked: false,
                }}
            >
                <LoginError error={error} />
                <FeedbackMessage />

                <FormGroup>
                    <Label>
                        <Trans id="account.login.emailLabel">
                            Email address
                        </Trans>
                        <Email
                            field="username"
                            disabled={Boolean(dataEntered)}
                        />
                    </Label>
                </FormGroup>

                {customerExists && (
                    <FormGroup>
                        <Label>
                            <Trans id="account.login.passwordLabel">
                                Password
                            </Trans>
                            <Password
                                field="password"
                                validate={validateRequired(
                                    t({
                                        id: 'account.login.passwordRequiredMessage',
                                        message: `Please enter your password`,
                                    }),
                                )}
                                autoComplete="current-password"
                                validateStrength={false}
                            />
                        </Label>
                    </FormGroup>
                )}

                {customerDoesntExists && (
                    <>
                        <FormGroup>
                            <Label field="firstName">
                                <Trans id="core.addressInput.firstNameLabel">
                                    First name
                                </Trans>
                            </Label>
                            <TextInput
                                field="firstName"
                                autoComplete="given-name"
                                validate={validateRequired(
                                    t({
                                        id: 'formValidation.firstNameRequired',
                                        message: `First name is required`,
                                    }),
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label field="lastName">
                                <Trans id="core.addressInput.lastNameLabel">
                                    Last name
                                </Trans>
                            </Label>
                            <TextInput
                                field="lastName"
                                autoComplete="family-name"
                                validate={validateRequired(
                                    t({
                                        id: 'formValidation.lastNameRequired',
                                        message: `Last name is required`,
                                    }),
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                <Trans id="account.register.passwordLabel">
                                    Password
                                </Trans>
                                <Password
                                    field="password"
                                    validate={validateRequired(
                                        t({
                                            id: 'account.login.passwordRequiredMessage',
                                            message: `Please enter your password`,
                                        }),
                                    )}
                                    validateStrength
                                    autoComplete="new-password"
                                />
                            </Label>
                        </FormGroup>

                        <FormGroup>
                            <StyledCheckBox
                                field="gdprChecked"
                                validate={validateRequired(
                                    t({
                                        id: 'account.login.gdprRequired',
                                        message: `Please accept the terms and conditions and privacy policy`,
                                    }),
                                )}
                            >
                                <Text>
                                    <Trans id="account.register.gdpr">
                                        Yes, I agree to the {termsLink},{' '}
                                        {rewardsLink} and {privacyPolicyLink}.
                                    </Trans>
                                </Text>
                            </StyledCheckBox>
                        </FormGroup>
                    </>
                )}

                {customerExists && (
                    <ForgotPasswordLink
                        name="Forgot passsord?"
                        category="account.login"
                        to={paths.checkoutResetPassword}
                    >
                        <Trans id="account.login.toForgotPassword">
                            Forgot your password?
                        </Trans>
                    </ForgotPasswordLink>
                )}

                <Submit
                    variant="primary"
                    wide
                    name="Log in"
                    category="checkout.login.submit"
                    loading={isLoading || checkingEmail}
                    disabled={isLoading || checkingEmail}
                    type="submit"
                >
                    {!dataEntered ? (
                        <Trans id="account.login.continue">Continue</Trans>
                    ) : customerDoesntExists ? (
                        <Trans id="account.login.submitCreateAccount">
                            Create account
                        </Trans>
                    ) : (
                        <Trans id="account.login.submit">Log in</Trans>
                    )}
                </Submit>

                {dataEntered?.email && !checkingEmail && (
                    <ContinueAsGuestButton
                        handleLoading={(isLoading) =>
                            setGuestLoading(isLoading)
                        }
                        handleError={(error) => setGuestError(error)}
                    />
                )}

                {dataEntered?.email && !checkingEmail && (
                    <BackButton
                        variant="link"
                        onClick={() => {
                            setGuestError(undefined)
                            setDataEntered(undefined)
                            window.scrollTo(0, 0)
                        }}
                        name=""
                        category=""
                    >
                        <Icon
                            component={StyledBackIcon}
                            title={t({
                                id: 'core.productPageTopBar.backButtonLabel',
                                message: `Back`,
                            })}
                        />
                        <Trans id="account.login.back">Back</Trans>
                    </BackButton>
                )}
            </Form>
        </>
    )
}

export default CheckoutAccessForm
