import { getCoreConfig } from '../coreConfig.query'
import { Action, ThunkResult } from '../types/Redux'

export enum CoreConfigActionTypes {
    SET_CORE_CONFIG = 'SET_CORE_CONFIG',
}
export interface CoreConfig {
    [key: string]: string | null
}
export interface SetCoreConfigAction extends Action {
    type: CoreConfigActionTypes.SET_CORE_CONFIG
    payload: CoreConfig
}

export const setCoreConfig = (data: CoreConfig): SetCoreConfigAction => ({
    type: CoreConfigActionTypes.SET_CORE_CONFIG,
    payload: data,
})
