import { Trans } from '@lingui/macro'
import Heading from 'components/src/typography/Heading'

import styles from './MemberCardBody.module.scss'

// eslint-disable-next-line react/display-name
export default () => (
    <div className={styles.header}>
        <span className={styles.fuss} />
        <Heading variant="h3" element="h3" className={styles.cardTitle}>
            <Trans id="user.membercard.card.title">Membercard</Trans>
        </Heading>
        <span className={styles.fuss} />
    </div>
)
