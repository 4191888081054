import { stripMaybes } from '@emico-utils/graphql-data-utils'

import toGA4Item from './toGAItem'
import { useCartItems as useCart } from '../../cart/CartPage/useCartItems'
import { useRootCategoryId } from '../../catalog/useRootCategory'

const useCartItems = () => {
    const rootCategoryId = useRootCategoryId()
    const { items, prices } = useCart()

    if (!rootCategoryId) {
        return { items: undefined, prices: undefined }
    }

    return {
        items: items?.filter(stripMaybes).map((cartItem) =>
            toGA4Item(cartItem, rootCategoryId, {
                quantity: cartItem.quantity,
            }),
        ),
        prices,
    }
}

export default useCartItems
