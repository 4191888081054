import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import * as React from 'react'

import { minWidth } from '@emico/styles'

import BackIcon from '../core/BackIcon'
import ContactUsModalTransition from '../core/ContactUsModal/ContactUsModalTransition'
import FooterBottom from '../core/Footer/FooterBottom'
import getHeadingDecorationVisibility from '../HeadingDecoration'
import Container from '../layout/Container'
import Icon from '../media/Icon'
import Link from '../navigation/Link'
import paths from '../paths'
import theme from '../theme'
import Heading from '../typography/Heading'

const linkStyle = css`
    white-space: nowrap;
    margin-top: 18px;
`

const ContactUsLink = styled.div`
    ${linkStyle}

    @media ${minWidth('lg')} {
        order: 2;
    }
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    border-top: 1px solid ${theme.colors.grayLight};

    @media ${minWidth('lg')} {
        flex-flow: row nowrap;
    }
`

const CheckoutFooter = () => {
    const [contactUs, setContactUs] = React.useState<boolean>(false)

    return (
        // eslint-disable-next-line react/no-unknown-property
        <footer css={getHeadingDecorationVisibility(false)}>
            <Container>
                <Wrapper>
                    <div>
                        <Link
                            name="Back to cart"
                            category="checkoutFooter"
                            to={paths.cart}
                            variant="dark"
                            css={css`
                                order: 0;
                                display: flex;
                                flex-flow: row nowrap;
                                align-items: center;
                                justify-content: center;
                                ${linkStyle}
                            `}
                        >
                            <Icon
                                component={BackIcon}
                                title=""
                                css={css`
                                    margin-right: 0.5em;
                                `}
                            />
                            <Trans id="core.checkoutFooter.backToCartLink">
                                Back to cart
                            </Trans>
                        </Link>
                    </div>

                    <ContactUsLink>
                        <Trans id="core.checkoutFooter.helpLink">
                            <Heading element="span" variant="h4" color="dark">
                                Need help?
                            </Heading>{' '}
                            <Link
                                name="Contact us now"
                                category="checkoutFooter"
                                to="/contact"
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault()
                                    setContactUs(true)
                                }}
                                variant="dark"
                            >
                                Contact us now
                            </Link>
                        </Trans>
                    </ContactUsLink>

                    <ContactUsModalTransition
                        open={contactUs}
                        close={() => setContactUs(false)}
                    />

                    <FooterBottom showPayment />
                </Wrapper>
            </Container>
        </footer>
    )
}

export default CheckoutFooter
