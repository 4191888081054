import { useCallback, useRef, useState } from 'react'

type OnChangeCallback<T> = (imageNode: T, x: number, y: number) => void

export function useImageZoomHover<T extends HTMLElement>(
    onChange: OnChangeCallback<T>,
) {
    const onChangeRef = useRef<OnChangeCallback<T>>(onChange)
    const mouseX = useRef<number>(0)
    const mouseY = useRef<number>(0)
    const isRunning = useRef<boolean>(false)
    const currTarget = useRef<T | null>(null)
    const zoomTimeout = useRef<NodeJS.Timeout | null>(null)

    const [hasZoom, setHasZoom] = useState<boolean>(false)

    const animation = useCallback(() => {
        if (currTarget.current && isRunning.current) {
            const target = currTarget.current
            const { left, top } = target.getBoundingClientRect()
            const x = ((mouseX.current - left) / target.offsetWidth) * 100
            const y = ((mouseY.current - top) / target.offsetHeight) * 100
            onChangeRef.current(target, x, y)
            isRunning.current = false
        }
    }, [])

    const mouseMoveHandler = useCallback(
        (e: MouseEvent) => {
            if (
                !isRunning.current &&
                typeof onChangeRef.current === 'function'
            ) {
                const target = e.currentTarget as T
                currTarget.current = target
                isRunning.current = true
                mouseX.current = e.clientX
                mouseY.current = e.clientY
                requestAnimationFrame(animation)
            }
        },
        [animation],
    )

    const setImageRef = useCallback(
        (imageNode: T) => {
            if (imageNode) {
                imageNode.onmouseenter = () => {
                    // For nicer effect, do not start immediately
                    zoomTimeout.current = setTimeout(() => {
                        setHasZoom(true)
                    }, 200)
                }
                imageNode.onmousemove = mouseMoveHandler
                imageNode.onmouseleave = () => {
                    if (zoomTimeout.current) {
                        clearTimeout(zoomTimeout.current)
                    }
                    setHasZoom(false)
                }
            }
        },
        [mouseMoveHandler],
    )

    return { setImageRef, hasZoom }
}
