import { Image } from '@emico-react/image'
import classNames from 'classnames'

import styles from './ImageZoomDesktop.module.scss'
import { useImageZoomHover } from '../../../behaviour/useImageZoomHover'
import { MediaGalleryEntry } from '../../../ProductCardFragment'
import makeMediaUrl, { MediaType } from '../../../utils/makeMediaUrl'

export const ImageZoomDesktop = ({ image }: { image: MediaGalleryEntry }) => {
    const { setImageRef, hasZoom } = useImageZoomHover<HTMLDivElement>(
        (node, x, y) => {
            node.style.transformOrigin = `${x}% ${y}%`
        },
    )

    return (
        <div className={styles.zoomContainer} ref={setImageRef}>
            <Image
                url={makeMediaUrl(image.file, MediaType.Product)}
                width={1500}
                alt={image.label ? image.label : ''}
                lazy
                className={classNames(styles.image, hasZoom ? styles.zoom : '')}
            />
        </div>
    )
}
