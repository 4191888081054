import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import { ImageZoomDesktop } from './ImageZoomDesktop'
import { ProductLabel } from '../../../graphql/schema.generated'
import { MaveClip } from '../../../mave/MaveClip'
import ProdLabel from '../../../presentation/ProductLabel'
import { DynamicProductLabels } from '../../../presentation/ProductLabel/DynamicProductLabels'
import { MediaGalleryEntry } from '../../../ProductCardFragment'

interface Props {
    images: MediaGalleryEntry[]
    title: string
    labelType?: string
    labelText?: string
    labels?: ProductLabel[]
}

const Grid = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media ${minWidth('lg')} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const StyledButton = styled(ButtonUnstyled)`
    position: relative;
`

const ImageSliderDesktop = ({
    images,
    labelType,
    labelText,
    labels,
}: Props) => {
    const imageList = images.map((image, index) => {
        const isVideo = image.mave !== null

        return (
            <StyledButton
                key={`image-${image.file.replace(/^.*(\\|\/|:)/, '')}`}
                analyticsContext="productPage"
                analyticsName="imageZoom"
            >
                {index === 0 && (
                    <DynamicProductLabels labels={labels ?? []}>
                        <ProdLabel variant={labelType ?? ''} text={labelText} />
                    </DynamicProductLabels>
                )}

                {isVideo ? (
                    <MaveClip embed={image.mave?.id ?? ''} loop />
                ) : (
                    <ImageZoomDesktop image={image} />
                )}
            </StyledButton>
        )
    })

    return images.length > 1 ? <Grid>{imageList}</Grid> : <>{imageList}</>
}

export default ImageSliderDesktop
