import { StoreView } from '@emico/storeviews'

export function getBusinessByStoreView(storeView: StoreView) {
    const [store] = storeView.code.split('_')
    const language = storeView.locale

    switch (store) {
        case 'pme':
        case 'outlet':
            return {
                name: 'PME Legend',
                language,
            }
        case 'jbfo':
            return {
                name: 'JBFO',
                language,
            }
        case 'castiron':
            return {
                name: 'Cast Iron',
                language,
            }
        case 'vanguard':
            return {
                name: 'Vanguard',
                language,
            }
        case 'monster':
            return {
                name: 'Monstersale',
                language,
            }
        default:
            return {
                name: 'JustBrands',
                language,
            }
    }
}
