import styled from '@emotion/styled'
import { createPopper, Placement } from '@popperjs/core'
import { FocusScope } from '@react-aria/focus'
import { DismissButton, useOverlay } from '@react-aria/overlays'
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import styles from './PopperPopover.module.css'
import theme from '../theme'

const Popover = styled('div', {
    shouldForwardProp: (prop: string) =>
        !['popperPosition', 'width'].includes(prop),
})<{ popperPosition: 'top' | 'bottom'; width?: number }>`
    position: fixed;
    top: 40px;
    ${({ width }) => (width ? `min-width: ${width}px` : 'min-width: auto')};
    background: ${theme.colors.background};
    border: solid thin ${theme.colors.border};
    ${({ popperPosition }) =>
        popperPosition === 'top' ? 'border-bottom: none;' : 'border-top: none;'}
    box-shadow: ${({ popperPosition }) =>
        popperPosition === 'top' ? '0' : '0 4px 3px -2px rgba(0, 0, 0, 0.1)'};
    border-radius: ${({ popperPosition }) =>
        popperPosition === 'top' ? '3px 3px 0 0' : '0 0 3px 3px'};
    z-index: 1005;
`

const TopLine = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    height: 1px;
    background: ${theme.colors.border};
`

interface Props {
    popoverRef?: RefObject<HTMLDivElement>
    triggerRef?: RefObject<HTMLButtonElement>
    isOpen?: boolean
    onClose?: () => void
    children: ReactNode
    defaultPopperPosition?: Placement
    width?: number
}

const PopperPopover = ({
    triggerRef,
    defaultPopperPosition = 'bottom-start',
    width,
    ...props
}: Props) => {
    const ref = useRef<HTMLDivElement>(null)
    const { popoverRef = ref, isOpen, onClose, children } = props
    const [popperPosition, setPopperPosition] = useState<Placement>(
        defaultPopperPosition,
    )

    useEffect(() => {
        document.body.classList.add(styles.noScroll)
        return () => {
            document.body.classList.remove(styles.noScroll)
        }
    }, [])

    useEffect(() => {
        const button = triggerRef?.current
        const popover = popoverRef.current

        if (button && popover) {
            const popper = createPopper(button, popover, {
                placement: defaultPopperPosition,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [
                                'bottom-start',
                                'top-start',
                                'bottom-end',
                                'top-end',
                            ],
                        },
                    },
                    {
                        name: 'topLogger',
                        enabled: true,
                        phase: 'main',
                        fn({ state }) {
                            if (state.placement !== popperPosition) {
                                setPopperPosition(state.placement)
                            }
                        },
                    },
                ],
            })

            return () => {
                popper.destroy()
            }
        }

        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { overlayProps } = useOverlay(
        {
            isOpen,
            onClose,
            shouldCloseOnBlur: true,
            isDismissable: true,
        },
        popoverRef,
    )

    const isBottom = popperPosition.includes('bottom')

    return (
        <FocusScope restoreFocus>
            {createPortal(
                <Popover
                    {...overlayProps}
                    ref={popoverRef}
                    width={width}
                    popperPosition={isBottom ? 'bottom' : 'top'}
                >
                    {isBottom && <TopLine />}

                    {children}
                    <DismissButton onDismiss={onClose} />
                    {!isBottom && <TopLine />}
                </Popover>,
                document.body,
            )}
        </FocusScope>
    )
}

export default PopperPopover
