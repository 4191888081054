import { useActiveStoreView } from '@emico/storeviews'

import { MEMBERCARD_WHITELIST } from '../../constants'

export function storeHasMemberCard(storeCode: string): boolean {
    return Boolean(MEMBERCARD_WHITELIST?.includes(storeCode))
}

export function useHasMemberCard() {
    const storeView = useActiveStoreView()

    return storeHasMemberCard(storeView.code)
}
