import cx from 'classnames'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Slider from 'react-slick'

import { useBreakpoints } from '@emico/ui'

import Card from './Card'
import styles from './HeroVideo.module.scss'
import ProgressBar from './ProgressBar'
import Carousel from '../../../behaviour/Carousel'
import { PageBodyProductHeroPrimary } from '../../../graphql/schema.generated'
import {
    CuePointEvent,
    PlaybackEvent,
    Player,
    Video,
} from '../../../media/Video'
import { ProductCardFragment } from '../../../ProductCardFragment'
import useProductsBySku from '../../../useProductsBySku'
import { useViewPromotionEvent } from '../../../utils/ga4/useViewPromotionEvent'
import hasHoverSupport from '../../../utils/hasHoverSupport'
import { RenderContent } from '../Hero/Hero'

export interface HeroShopableVideoProduct {
    sku: string
    cuePoint: number
}

type HeroVideoProps = React.HTMLAttributes<HTMLDivElement> &
    Pick<
        PageBodyProductHeroPrimary,
        | 'videoId'
        | 'content'
        | 'buttonUrl'
        | 'button2Url'
        | 'buttonText'
        | 'button2Text'
        | 'subheading'
        | 'buttonStyle'
        | 'textStyle'
        | 'subheadingStyle'
    > & {
        products: HeroShopableVideoProduct[] | undefined
    }

type HeroShopableVideoProps = Omit<
    HeroVideoProps,
    | 'buttonStyle'
    | 'buttonUrl'
    | 'buttonText'
    | 'products'
    | 'button2Text'
    | 'button2Url'
> & {
    products: HeroShopableVideoProduct[]
}

const DISABLE_CUEPOINT = 'disableCuepoint'
const VIMEO_TIME_UPDATE_INTERVAL = 0.3
const CONTENT_VISIBLE_DURATION = 5

const addCuePoints = (player: Player, products: HeroShopableVideoProduct[]) => {
    products.forEach(({ sku, cuePoint }) => {
        player
            .addCuePoint(cuePoint, {
                sku,
            })
            .catch((error) => {
                console.error(error)
            })
    })
}

const HeroShopableVideo = ({
    videoId,
    content,
    products,
    textStyle,
    subheading,
    subheadingStyle,
    ...divProps
}: HeroShopableVideoProps) => {
    const { pathname } = useLocation()

    const ref = useViewPromotionEvent<HTMLDivElement>({
        item_id: `hero video - ${pathname}`,
        location_id: `hero video - ${pathname}`,
        promotion_name: `${content} ${subheading}`,
        creative_name: `${videoId}`,
    })

    const videoPlayer = React.useRef<Player | null>(null)
    const carouselRef = React.useRef<Slider | null>(null)

    const { isMobile, isPortrait } = useBreakpoints()
    const [isVideoReady, setVideoReady] = React.useState<boolean>(false)
    const [hasVideoEnded, setVideoEnded] = React.useState<boolean>(false)
    const [hasVisibleCards, setHasVisibleCards] = React.useState<boolean>(false)
    const [productsForCuePoints, setProductsForCuePoints] = React.useState<
        HeroShopableVideoProduct[]
    >([])

    const [progress, setProgress] = React.useState<PlaybackEvent>({
        seconds: 0,
        percent: 0,
        duration: 0,
    })
    const hasSupportForHover = hasHoverSupport()

    // A card becomes "active" when its quepoint in the video is reached.
    // It becomes "inactive" when the next quepoint is reached, or when
    // the video ends
    const [activeCard, setActiveCard] = React.useState<string>()

    // A card becomes "selected" when it is clicked on (mobile) or hovered on (desktop).
    const [selectedCard, setSelectedCard] = React.useState<string>()

    // Skip query when there are no sku's set
    const productSkus = products.map(({ sku }) => sku)
    const { loading, error, data } = useProductsBySku(productSkus, {
        skip: productSkus.length === 0,
    })

    const showCarousel =
        isMobile &&
        isPortrait &&
        process.env.REACT_APP_FEATURE_HERO_VIDEO_MOBILE_CAROUSEL !== 'false'

    // Apply useMemo because [...data].sort, productData is different on each render.
    // Because productData is used in other hooks as a dependence, this causes an infinite loop
    const productData = React.useMemo(
        () =>
            !loading && !error && data !== undefined && data.length > 0
                ? // Sadly the graphql item order is not the same as the order of the items we passed in.
                  // And because that order matters, we need to sort by the items passed to this function.
                  [...data].sort(
                      (a, b) =>
                          productSkus.indexOf(a.sku) -
                          productSkus.indexOf(b.sku),
                  )
                : undefined,

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, error],
    )

    React.useEffect(() => {
        if (!videoPlayer.current || !productData || productData.length === 0) {
            return
        }

        // // If there is product data, we know which SKU's are valid.
        // // Use those to add cue points to the video.
        // // If an SKU isnt valid, leave it out.
        const validSkus = productData.map((p) => p.sku)
        const existingProducts = products.filter(({ sku }) =>
            validSkus.includes(sku),
        )

        addCuePoints(videoPlayer.current, existingProducts)
        setProductsForCuePoints(existingProducts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlayer.current, products, productData])

    const handleVideoReady = (player: Player) => {
        videoPlayer.current = player
        setVideoReady(true)
    }

    const handleProductListCardClick = React.useCallback(
        (
            event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            product: ProductCardFragment,
        ) => {
            event.stopPropagation()

            if (selectedCard !== product.sku) {
                setActiveCard(undefined)
                handleProductListCardHover(product, true)
                event.preventDefault()
            }

            setSelectedCard(
                selectedCard !== product.sku ? product.sku : undefined,
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCard, hasSupportForHover, isPortrait],
    )

    const handleProductListCardHover = React.useCallback(
        (product: ProductCardFragment, isHovered: boolean) => {
            if (!videoPlayer.current) {
                return
            }

            if (isHovered && selectedCard !== product.sku) {
                setSelectedCard(product.sku)
                if (activeCard !== product.sku) {
                    const cuePoint = productsForCuePoints.find(
                        ({ sku }) => sku === product.sku,
                    )

                    if (cuePoint && cuePoint.cuePoint) {
                        videoPlayer.current
                            .setCurrentTime(cuePoint.cuePoint)
                            .catch((e) => {
                                console.error(e)
                            })
                    }
                }
            }

            if (selectedCard === product.sku && !isHovered) {
                setSelectedCard(undefined)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            selectedCard,
            activeCard,
            videoPlayer.current,
            setSelectedCard,
            productsForCuePoints,
        ],
    )

    const handleCarouselCardClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        product: ProductCardFragment,
    ) => {
        if (!videoPlayer.current) {
            return
        }

        const cuePoint = productsForCuePoints.find(
            ({ sku }) => sku === product.sku,
        )?.cuePoint

        videoPlayer.current.setCurrentTime(cuePoint ?? 0).catch((e) => {
            console.error(e)
        })
    }

    /**
     * Triggers on all carousel events, both user and programatically initiated
     */
    const handleCarouselBeforeChange = (oldIndex: number, newIndex: number) => {
        if (!videoPlayer.current || !carouselRef.current) {
            return
        }

        // When video reaches its end and starts again,
        // the carousel scrolls to the first card, triggering this function,
        // which should do nothing in that case
        if (hasVideoEnded) {
            setVideoEnded(false)
            return
        }

        const product = productsForCuePoints[newIndex]
        const cuePoint = product.cuePoint

        setSelectedCard(DISABLE_CUEPOINT)
        setActiveCard(product.sku)

        videoPlayer.current.setCurrentTime(cuePoint).catch((e) => {
            console.error(e)
        })

        setSelectedCard(undefined)
    }

    const handleTimeUpdate = (data: PlaybackEvent) => {
        setProgress(data)

        if (data.duration - data.seconds < VIMEO_TIME_UPDATE_INTERVAL) {
            onVideoEnd()
        }
    }

    const onVideoEnd = () => {
        if (!carouselRef.current) {
            return
        }
        setVideoEnded(true)
        carouselRef.current.slickGoTo(0)
    }

    const handleCuePoint = (event: CuePointEvent) => {
        const data = event.data as {
            sku: string
        }

        if (
            carouselRef.current &&
            productsForCuePoints &&
            selectedCard !== DISABLE_CUEPOINT
        ) {
            // When the video reaches a certain point in time,
            // show the card that belongs to that cuepoint
            const activeSlideIndex = productsForCuePoints.findIndex(
                (product) => product.sku === data.sku,
            )

            carouselRef.current.slickGoTo(activeSlideIndex)
        }

        setActiveCard(data.sku)
    }

    const handleVisibilityChange = (isVisible: boolean) => {
        setHasVisibleCards(isVisible)
    }

    const deselectSelectedCard = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        setSelectedCard(undefined)
        setActiveCard(undefined)
        setHasVisibleCards(false)
    }

    if (!videoId) {
        return null
    }

    return (
        <div
            ref={videoId ? ref : undefined}
            className={cx(styles.base, styles.withProducts, {
                [styles.withCarousel]: showCarousel,
                // Determine content visibility based on
                // if the video started playing,
                // and when it's finished playing
                [styles.videoWillStart]: progress.seconds > 0,
                [styles.videoWillEnd]:
                    progress.duration > 0 &&
                    progress.seconds > 0.5 &&
                    progress.duration - progress.seconds <
                        CONTENT_VISIBLE_DURATION,
            })}
            {...divProps}
            onClick={deselectSelectedCard}
        >
            <div className={styles.videoContainer}>
                <Video
                    url={videoId}
                    responsive={false}
                    onReady={handleVideoReady}
                    onCuePoint={handleCuePoint}
                    onTimeUpdate={handleTimeUpdate}
                    background
                    loop
                    autoplay
                    muted
                />
            </div>

            {productData && showCarousel && (
                <Carousel className={styles.carousel}>
                    {productData.map((product, index) => (
                        <Card
                            carouselEnabled
                            isVideoReady={isVideoReady}
                            key={product.sku}
                            product={product}
                            onClick={handleCarouselCardClick}
                            progress={progress}
                            data-card={index + 1}
                            selectedCard={selectedCard}
                            activeCard={activeCard}
                        />
                    ))}
                </Carousel>
            )}

            {productData && !showCarousel && (
                <div
                    className={styles.productList}
                    data-hasvisiblecards={hasVisibleCards}
                >
                    {productData.map((product) => (
                        <Card
                            carouselEnabled={false}
                            isVideoReady={isVideoReady}
                            key={product.sku}
                            product={product}
                            progress={progress}
                            selectedCard={selectedCard}
                            activeCard={activeCard}
                            onClick={handleProductListCardClick}
                            onHover={handleProductListCardHover}
                            onVisibilityChange={handleVisibilityChange}
                        />
                    ))}
                </div>
            )}

            <div className={styles.content}>
                {content && (
                    <RenderContent
                        content={content}
                        textStyle={textStyle ?? undefined}
                        locationId={`hero video - ${pathname}`}
                        subheading={subheading ?? undefined}
                        subheadingStyle={subheadingStyle ?? undefined}
                    />
                )}
            </div>

            <ProgressBar progress={progress.percent * 100} />
        </div>
    )
}

const HeroVideo = ({
    videoId,
    content,
    products,
    buttonUrl,
    button2Url,
    buttonText,
    button2Text,
    buttonStyle,
    textStyle,
    subheading,
    subheadingStyle,
    ...divProps
}: HeroVideoProps) => {
    const { pathname } = useLocation()

    const ref = useViewPromotionEvent<HTMLDivElement>({
        item_id: `hero video - ${pathname}`,
        location_id: `hero video - ${pathname}`,
        promotion_name: `${content} ${subheading} ${buttonText} ${button2Text}`,
        creative_name: `${videoId}`,
    })

    if (!videoId) {
        return null
    }

    if (products && products.length > 0) {
        return (
            <HeroShopableVideo
                videoId={videoId}
                content={content}
                products={products}
                textStyle={textStyle}
                subheading={subheading}
                subheadingStyle={subheadingStyle}
                {...divProps}
            />
        )
    }

    return (
        <div
            className={cx(styles.base, styles.noProducts)}
            {...divProps}
            ref={videoId ? ref : undefined}
        >
            <div className={styles.videoContainer}>
                <Video
                    url={videoId}
                    responsive={false}
                    background
                    loop
                    autoplay
                    muted
                />
            </div>

            {content && (
                <div className={styles.content}>
                    <RenderContent
                        content={content}
                        textStyle={textStyle ?? undefined}
                        locationId={`hero video - ${pathname}`}
                        buttonStyle={buttonStyle ?? undefined}
                        buttonText={buttonText ?? undefined}
                        buttonUrl={buttonUrl ?? undefined}
                        button2Text={button2Text ?? undefined}
                        button2Url={button2Url ?? undefined}
                        subheading={subheading ?? undefined}
                        subheadingStyle={subheadingStyle ?? undefined}
                    />
                </div>
            )}
        </div>
    )
}

export default HeroVideo
