import cx from 'classnames'
import * as React from 'react'

import styles from './WizardProgressBar.module.scss'
import Container from '../../layout/Container'
import Link from '../../navigation/Link'
import Text from '../../typography/Text'
import overridable from '../../utils/overridable'

interface Props {
    steps: Array<{
        title: string
        url: string
        isActive: boolean
    }>
    className?: string
}

const WizardProgressBarLayout = ({ steps, className }: Props) => (
    <Container className={cx(styles.container, className)}>
        <ol className={styles.list}>
            {steps.map((step, index) => (
                <React.Fragment key={`${index}-${step}`}>
                    {index !== 0 && (
                        <li
                            className={cx(styles.spacer, {
                                [styles.active]: step.isActive,
                            })}
                        />
                    )}
                    <li className={cx({ [styles.active]: step.isActive })}>
                        <Link
                            name={`Step ${index + 1}`}
                            category="checkout.wizard"
                            to={step.url}
                        >
                            <span className={styles.indicator}>
                                {index + 1}
                            </span>
                            <Text
                                as="span"
                                color="dark"
                                className={styles.title}
                            >
                                {step.title}
                            </Text>
                        </Link>
                    </li>
                </React.Fragment>
            ))}
        </ol>
    </Container>
)

export default overridable('WizardProgressBar')(WizardProgressBarLayout)
