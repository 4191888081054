import { Image } from '@emico-react/image'
import cx from 'classnames'

import styles from './Banner.module.scss'
import Link from '../../../navigation/Link/Link'

export interface Props
    extends React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
    imageUrl: string
    to?: string
    className?: string
    children?: React.ReactNode
}

const Banner = ({
    imageUrl,
    to,
    className,
    children,
    ...otherProps
}: Props) => {
    const Component = to ? Link : 'div'

    return (
        <Component
            {...otherProps}
            name="Banner"
            category="navigation.subNav"
            to={to ?? ''}
            resolver
            className={cx(styles.base, className)}
        >
            <Image
                alt=""
                url={imageUrl}
                width={250}
                lazy
                className={styles.image}
            />
            {children}
        </Component>
    )
}

export default Banner
