import * as React from 'react'

import { useSelector } from '../types'
import { pushEnhancedConversionEmailHash } from '../utils/ga4/usePushEnhancedConversionEmailHash'
import checkout from '../utils/googleTagManager/checkout'
import push from '../utils/googleTagManager/push'

const useCheckoutGoogleTagManager = (step: number) => {
    const cartDetails = useSelector((state) => state.cart.details)
    const email = useSelector((state) => state.checkout.email)

    React.useEffect(() => {
        if (!email) {
            return
        }
        pushEnhancedConversionEmailHash(email)
    }, [email])

    React.useEffect(() => {
        push(
            checkout(
                step,
                // it redirects if empty so this should always be set
                cartDetails?.items?.map((cartItem) => ({
                    id: cartItem.product_sku,
                    sku: cartItem.sku,
                    name: cartItem.product_name,
                    price: String(cartItem.price),
                    quantity: cartItem.qty,
                })) ?? [],
            ),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useCheckoutGoogleTagManager
