import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    ResetPasswordByCustomerIdMutation,
    ResetPasswordByCustomerIdMutationVariables,
} from './useResetPasswordByCustomerId.generated'

const resetPasswordByCustomerIdMutation = gql`
    mutation ResetPasswordByCustomerId(
        $customerId: Int!
        $resetPasswordToken: String!
        $newPassword: String!
    ) {
        resetPasswordByCustomerId(
            customerId: $customerId
            resetPasswordToken: $resetPasswordToken
            newPassword: $newPassword
        )
    }
` as TypedDocumentNode<
    ResetPasswordByCustomerIdMutation,
    ResetPasswordByCustomerIdMutationVariables
>

/**
 * Mutation to reset the password of a customer account using their customer ID
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const resetPasswordByCustomerId = useResetPasswordByCustomerId()
 *
 *  return useCallback(
 *    async () => {
 *      try {
 *        await resetPasswordByCustomerId(customerId, resetPasswordToken, newPassword)
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [resetPasswordByCustomerId],
 *  )
 * }
 * ```
 */
export function useResetPasswordByCustomerId() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    return useCallback(
        /**
         * @param customerId The customer's ID
         * @param resetPasswordToken A runtime token generated by the requestPasswordResetEmail mutation
         * @param newPassword The customer's new password
         */
        (customerId: number, resetPasswordToken: string, newPassword: string) =>
            client.mutate({
                mutation: resetPasswordByCustomerIdMutation,
                variables: {
                    customerId,
                    resetPasswordToken,
                    newPassword,
                },
                context: authorizationContext,
            }),
        [authorizationContext, client],
    )
}
