import styled from '@emotion/styled'

import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import styles from './PrismicHtml.module.scss'
import theme from './theme'
import useGridProps from './useGridProps'

export interface Props {
    style?: LayoutStyle
    html?: string
    tablet?: string
    desktop?: string
}

const Section = styled.section`
    background: var(--background-override, ${theme.colors.background});
    color: var(--text-color-override);
`

const PrismicHtml = ({ html, tablet, desktop, style = 'light' }: Props) => {
    const gridProps = useGridProps(tablet, desktop)
    const layoutStyle = getLayoutStyleVars(style)

    if (!html) {
        return null
    }

    return (
        <Section css={layoutStyle} {...gridProps}>
            <Container className={styles.container}>
                <Row className={styles.row}>
                    <Col className={styles.column}>
                        {html && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: html,
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default PrismicHtml
