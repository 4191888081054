import { DLVCheckout, DLVCheckoutProduct } from './types'

export default function checkout(
    step: number,
    products?: DLVCheckoutProduct[],
): DLVCheckout {
    return {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step,
                },
                products,
            },
        },
    }
}
