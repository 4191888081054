import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cx from 'classnames'
import { useCallback, useEffect, useRef } from 'react'

import styles from './DesktopModal.module.scss'
import { Props } from './ResponsiveModal'
import CloseIcon from '../../core/CloseIcon'
import Button from '../../input/Button'
import Container from '../../layout/Container'
import PageOverlay from '../../layout/PageOverlay'
import Icon from '../../media/Icon'
import useKeyPress from '../../useKeyPress'

const DesktopModal = ({
    onBack,
    header,
    children,
    footer,
    enableScrollLock = false,
    small,
    className,
}: Props) => {
    const scrollContainer = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!scrollContainer.current || !enableScrollLock) {
            return
        }

        disableBodyScroll(scrollContainer.current, {
            reserveScrollBarGap: true,
            // allow touch move on any element within an element with the `body-scroll-lock-ignore` attribute
            allowTouchMove: (el) => {
                while (
                    el &&
                    el !== document.body &&
                    typeof el.getAttribute === 'function'
                ) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                        return true
                    }
                    el = el.parentNode as HTMLElement
                }
                return
            },
        })

        const oldContainer = scrollContainer.current

        return () => {
            if (!oldContainer) {
                return
            }
            enableBodyScroll(oldContainer)
        }
    }, [enableScrollLock, scrollContainer])

    const handleBack = useCallback(() => onBack && onBack(), [onBack])

    useKeyPress('Escape', handleBack)

    return (
        <>
            <PageOverlay className={styles.overlay} onClick={onBack} />
            <div
                className={cx(
                    styles.modal,
                    { [styles.small]: small },
                    className ?? '',
                )}
                ref={scrollContainer}
            >
                {header && (
                    <Container className={styles.header}>{header}</Container>
                )}
                <Container
                    className={styles.content}
                    body-scroll-lock-ignore="true"
                >
                    {children}
                </Container>
                {footer && (
                    <Container className={styles.footer}>{footer}</Container>
                )}
                <Button
                    variant="link"
                    className={styles.closeButton}
                    onClick={onBack}
                    name="Close"
                    category="presentation.responsiveModal.close"
                >
                    <Icon
                        component={CloseIcon}
                        title=""
                        className={styles.closeIcon}
                    />
                </Button>
            </div>
        </>
    )
}

export default DesktopModal
