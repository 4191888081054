import { useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import cx from 'classnames'
import type React from 'react'
import {
    ComponentProps,
    HTMLAttributes,
    ReactNode,
    useMemo,
    useState,
} from 'react'
import { NavLinkProps, useLocation } from 'react-router-dom'

import { minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import NavSection from './NavSection'
import styles from './SubNav.module.scss'
import { STORES_DISABLED, WISHLIST_DISABLED } from '../../../constants'
import { Responsive } from '../../../core/Responsive'
import { FlagBeIcon } from '../../../flags/BE'
import Link from '../../../navigation/Link'
import paths from '../../../paths'
import theme from '../../../theme'
import useSelectPromotionEvent from '../../../utils/ga4/useSelectPromotionEvent'
import { useViewPromotionEvent } from '../../../utils/ga4/useViewPromotionEvent'
import Container from '../../Container'
import Separator from '../../Separator'
import Banner, { BannerProps } from '../Banner/Banner'
import { mobileMenuVisibleVar } from '../Header'
import {
    languages,
    supportedLanguages,
    useLanguagePicker,
} from '../useLanguagePicker'

export interface MenuItem {
    id: number
    label: string
    to: string
    resolver?: ComponentProps<typeof Link>['resolver']
    isActive?: NavLinkProps['isActive']
    children?: MenuItem[]
    banner?: {
        imageUrl: string
        to: string
        text?: string
        btnLabel?: string
    }
    banner2?: {
        imageUrl: string
        to: string
        text?: string
        btnLabel?: string
    }
}
export interface Props extends HTMLAttributes<HTMLElement> {
    /**
     * Styling hook
     */
    className?: string

    /**
     * The menu items to be rendered
     */
    items: MenuItem[]

    /**
     * A component to be displayed in the desktop version as a banner
     */
    banner?: BannerProps

    /**
     * A component to be displayed in the desktop version as a banner
     */
    banner2?: BannerProps

    /**
     *  Function that is called when menu item is clicked
     */
    extendedMenu?: boolean
    onItemClick?(event: React.MouseEvent<unknown>): void
}

const flags: Record<string, ReactNode> = {
    be: <FlagBeIcon />,
}

const StyledContainer = styled(Container)`
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    @media ${minWidth('md')} {
        display: flex;
        flex-flow: row nowrap;
        gap: 20px;
    }
`

const StyledNav = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobileMenuVisible',
})<{
    isMobileMenuVisible: boolean
}>`
    @media ${minWidth('md')} {
        flex: 1;
    }

    ${(props) =>
        !props.isMobileMenuVisible &&
        `margin-left: ${theme.utils.topbarMenuOffset};`}
`

const Items = ({
    items,
    onToggle,
    activeItem,
    onItemClick,
    extendedMenu = false,
    level = 0,
}: {
    items: MenuItem[]
    onToggle(item: string): void
    activeItem: string
    extendedMenu?: boolean
    onItemClick?(event: React.MouseEvent<unknown>): void
    level?: number
}) => {
    const { pathname, search } = useLocation()
    const isMobileMenuVisible = useReactiveVar(mobileMenuVisibleVar)

    level = level + 1
    const levelClass = styles[`navLevel${level}`]

    const renderLink = (item: MenuItem) => (
        <Link
            name={item.label}
            category="subNav"
            preload="hover"
            className={cx(styles.link, levelClass, {
                [styles.extraMenuItem]: extendedMenu,
            })}
            to={item.to}
            resolver={item.resolver}
            onClick={onItemClick}
            activeClassName={
                `${pathname}${search}`.toLowerCase() === item.to.toLowerCase()
                    ? styles.activeLink
                    : ''
            }
            data-testid="subNav.item"
        >
            {item.label}
        </Link>
    )

    return (
        <>
            {items.map((item) => {
                if (!item.children || item.children.length === 0) {
                    return <li key={item.id}>{renderLink(item)}</li>
                }

                const categoryName = item.label

                if (isMobileMenuVisible && level < 2) {
                    return (
                        <li key={item.id}>
                            <NavSection
                                label={categoryName}
                                className={cx(
                                    styles.link,
                                    styles.mobileButton,
                                    levelClass,
                                )}
                                open={activeItem === categoryName}
                                onClick={() => onToggle(categoryName)}
                                iconClass={styles.dropdownIcon}
                                iconClassOpen={styles.dropdownIconOpen}
                            >
                                {item.children && (
                                    <Items
                                        items={item.children}
                                        onToggle={onToggle}
                                        activeItem={activeItem}
                                        onItemClick={onItemClick}
                                        level={level}
                                    />
                                )}
                            </NavSection>
                        </li>
                    )
                } else {
                    return (
                        <li key={item.id}>
                            {renderLink(item)}

                            {item.children && (
                                <ul>
                                    <Items
                                        items={item.children}
                                        onToggle={onToggle}
                                        activeItem={activeItem}
                                        onItemClick={onItemClick}
                                        level={level}
                                    />
                                </ul>
                            )}
                        </li>
                    )
                }
            })}
        </>
    )
}

const SubNav = ({
    className,
    items,
    banner,
    banner2,
    onItemClick,
    extendedMenu = false,
    ...otherProps
}: Props) => {
    const {
        pickerState,
        setPickerState,
        country,
        language,
        messages,
        showSwitcher,
        onSwitch,
    } = useLanguagePicker()
    const [activeItem, setActiveItem] = useState('')
    const isMobileMenuVisible = useReactiveVar(mobileMenuVisibleVar)

    const pushSelectPromotion = useSelectPromotionEvent()

    const refBanner1 = useViewPromotionEvent<HTMLDivElement>({
        item_id: 'subnav banner 1',
        location_id: 'subnav banner 1',
        promotion_name: `${banner?.text} - ${banner?.btnLabel}`,
        creative_name: banner?.imageUrl,
    })

    const refBanner2 = useViewPromotionEvent<HTMLDivElement>({
        item_id: 'subnav banner 2',
        location_id: 'subnav banner 2',
        promotion_name: `${banner2?.text} - ${banner2?.btnLabel}`,
        creative_name: banner2?.imageUrl,
    })

    const promotion = useMemo(
        () =>
            banner?.btnLabel
                ? {
                      // It's supposed to be a unique id of the promotion
                      // so I figured "SHOP SALE-30% off" makes sense.
                      id: `${banner.btnLabel}-${banner.text}`,
                      name: banner.btnLabel,
                      creative: 'SubNav banner',
                      position: 1,
                  }
                : undefined,
        [banner?.btnLabel, banner?.text],
    )

    const promotion2 = useMemo(
        () =>
            banner2?.btnLabel
                ? {
                      // It's supposed to be a unique id of the promotion
                      // so I figured "SHOP SALE-30% off" makes sense.
                      id: `${banner2.btnLabel}-${banner2.text}`,
                      name: banner2.btnLabel,
                      creative: 'SubNav banner2',
                      position: 2,
                  }
                : undefined,
        [banner2?.btnLabel, banner2?.text],
    )

    const handleBannerClick = (location: 1 | 2, banner: BannerProps) => {
        pushSelectPromotion({
            item_id: `subnav banner ${location}`,
            location_id: `subnav banner ${location}`,
            promotion_name: `${banner.text} - ${banner.btnLabel}`,
            creative_name: banner.imageUrl,
        })
    }

    // Navigation sections can be toggled
    const onToggle = (item: string) => {
        const value = item === activeItem ? '' : item

        setActiveItem(value)
    }

    const extendedMenuItems = []

    if (!WISHLIST_DISABLED) {
        extendedMenuItems.push({
            id: 1,
            to: paths.wishlist,
            children: undefined,
            label: t({
                id: 'core.topBar.wishlistButtonLabel',
                message: `Wishlist`,
            }),
        })
    }

    const storesLabel = t({
        id: 'core.mainNavigation.storesLabel',
        message: `Stores`,
    })

    const customerServiceLink = t({
        id: 'core.customerServiceLink',
        message: `/help`,
    })

    const customerServiceLabel = t({
        id: 'core.customerServiceLabel',
        message: `Customer service`,
    })

    if (!STORES_DISABLED && storesLabel) {
        extendedMenuItems.push({
            id: 11,
            to: paths.storeLocator,
            children: undefined,
            label: storesLabel,
        })
    }
    if (customerServiceLink && customerServiceLabel) {
        extendedMenuItems.push({
            id: 12,
            to: customerServiceLink,
            children: undefined,
            label: customerServiceLabel,
        })
    }

    return (
        <div {...otherProps} className={cx(styles.base, className)}>
            <StyledContainer>
                <StyledNav isMobileMenuVisible={isMobileMenuVisible}>
                    <ul
                        className={cx(styles.sectioned, {
                            [styles.desktop]: !isMobileMenuVisible,
                            [styles.mobile]: isMobileMenuVisible,
                        })}
                    >
                        <Items
                            items={items}
                            onToggle={onToggle}
                            activeItem={activeItem}
                            onItemClick={onItemClick}
                        />
                        <Separator />
                    </ul>
                    {extendedMenu && (
                        <ul
                            className={cx(styles.extra, {
                                [styles.desktop]: !isMobileMenuVisible,
                                [styles.mobile]: isMobileMenuVisible,
                            })}
                        >
                            <Items
                                items={extendedMenuItems}
                                extendedMenu
                                onToggle={onToggle}
                                activeItem={activeItem}
                                onItemClick={onItemClick}
                            />
                            {showSwitcher && (
                                <li>
                                    <ButtonUnstyled
                                        className={cx(
                                            styles.languagePicker,
                                            styles.link,
                                            styles.navLevel1,
                                        )}
                                        analyticsName=""
                                        analyticsContext=""
                                        onClick={() =>
                                            setPickerState((state) =>
                                                state === 'hidden'
                                                    ? 'requested'
                                                    : 'hidden',
                                            )
                                        }
                                    >
                                        {flags[country] ??
                                            country.toUpperCase()}
                                        <span>|</span>
                                        <span>{language.toUpperCase()}</span>
                                    </ButtonUnstyled>
                                    {pickerState === 'requested' && (
                                        <ul
                                            className={
                                                styles.languagePickerList
                                            }
                                        >
                                            <li>
                                                {
                                                    messages.languageQuestion[
                                                        pickerState
                                                    ]
                                                }
                                            </li>
                                            {supportedLanguages.map(
                                                (language) => (
                                                    <li key={language}>
                                                        <ButtonUnstyled
                                                            className={
                                                                styles.languagePicker
                                                            }
                                                            analyticsContext=""
                                                            analyticsName=""
                                                            onClick={() =>
                                                                onSwitch(
                                                                    language,
                                                                )
                                                            }
                                                        >
                                                            {flags[country] ??
                                                                country.toUpperCase()}
                                                            <span>|</span>
                                                            <span>
                                                                {
                                                                    languages[
                                                                        language
                                                                    ]
                                                                }
                                                            </span>
                                                        </ButtonUnstyled>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    )}
                                </li>
                            )}
                        </ul>
                    )}
                </StyledNav>

                {banner && (
                    <div ref={refBanner1}>
                        <Responsive lg up>
                            <Banner
                                {...banner}
                                onClick={(event) => {
                                    handleBannerClick(1, banner)

                                    if (onItemClick) {
                                        onItemClick(event)
                                    }
                                }}
                            />
                        </Responsive>
                    </div>
                )}
                {banner2 && (
                    <div ref={refBanner2}>
                        <Responsive lg up>
                            <Banner
                                {...banner2}
                                onClick={(event) => {
                                    handleBannerClick(2, banner2)

                                    if (onItemClick) {
                                        onItemClick(event)
                                    }
                                }}
                            />
                        </Responsive>
                    </div>
                )}
            </StyledContainer>
        </div>
    )
}

export default SubNav
