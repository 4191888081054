import { Trans } from '@lingui/macro'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './SkipToContentLinks.module.scss'

export const SkipToContentLinks = () => {
    const skipRef = useRef<HTMLDivElement>(null)

    const { pathname, hash } = useLocation()
    const fullPath = `${pathname}${hash}`
    const show = !fullPath.includes('#')

    useEffect(() => {
        if (show) {
            skipRef?.current?.focus()
        }
    }, [show])

    return show ? (
        <>
            <VisuallyHidden>
                <div ref={skipRef} tabIndex={1}></div>
            </VisuallyHidden>
            <div className={styles.container}>
                <a href="#content">
                    <Trans id="core.skiptocontent.main.label">
                        Navigate to main content
                    </Trans>
                </a>
            </div>
        </>
    ) : null
}
