import { ClassNames, css } from '@emotion/react'
import { t } from '@lingui/macro'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import SRSCartCleanup from '../../cart/SRSCartCleanup'
import Logo from '../../core/Logo'
import TopBar from '../../layout/Header/TopBar'
import PageWrapper from '../../layout/PageWrapper'
import paths from '../../paths'
import Loader from '../../presentation/Loader'
import Footer from '../Footer'
import WizardProgressBarLayout from '../WizardProgressBar'

type Props = OptionalProp<
    React.ComponentProps<typeof PageWrapper>,
    'pageType'
> & {
    step?: number
}

const CheckoutPageWrapper = ({ children, step, ...others }: Props) => {
    const { isMobile } = useBreakpoints()

    const progressBar =
        step !== undefined ? (
            <WizardProgressBarLayout
                steps={[
                    {
                        title: t({
                            id: 'checkout.wizardProgress.delivery',
                            message: `Delivery`,
                        }),
                        url: paths.checkoutDelivery,
                        isActive: step >= 0,
                    },
                    {
                        title: t({
                            id: 'checkout.wizardProgress.contactDetails',
                            message: `Check details`,
                        }),
                        url: paths.checkoutDetails,
                        isActive: step >= 1,
                    },
                    {
                        title: t({
                            id: 'checkout.wizardProgress.payment',
                            message: `Payment`,
                        }),
                        url: paths.checkoutPayment,
                        isActive: step >= 2,
                    },
                ]}
                css={
                    isMobile
                        ? css`
                              margin-top: -60px;
                              margin-bottom: 60px;
                          `
                        : undefined
                }
            />
        ) : null

    return (
        <ClassNames>
            {({ css, theme }) => (
                <PageWrapper
                    contentClassName={css`
                        padding-top: 60px;
                    `}
                    header={
                        <TopBar
                            logo={
                                <Logo
                                    variant="dark"
                                    location={
                                        isMobile ? 'header-small' : 'header'
                                    }
                                />
                            }
                            left={!isMobile ? progressBar : null}
                            css={css`
                                background: none;
                                ${isMobile
                                    ? `border-bottom: 1px solid ${theme.border};`
                                    : ''}
                            `}
                        />
                    }
                    footer={<Footer />}
                    pageType="checkout"
                    {...others}
                >
                    {isMobile && progressBar}
                    {children}
                    <SRSCartCleanup />
                </PageWrapper>
            )}
        </ClassNames>
    )
}

export default CheckoutPageWrapper
