import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import cx from 'classnames'
import { ComponentProps, useEffect, useRef } from 'react'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { useBreakpoints } from '@emico/ui'

import { Responsive } from './core/Responsive'
import { getPrismicComponents } from './htmlSerializer'
import Button from './input/Button'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import { Video } from './media/Video'
import styles from './TextWithMedia.module.scss'
import theme from './theme'
import breakpoints from './theme/breakpoints'
import ButtonVariant from './types/ButtonVariant'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'
import Text from './typography/Text'

// Image sizes are based on
// the maximum content space at their respective breakpoints
// F.e. the breakpoint for small to medium = 768,
// so the max small image width is 767
const imageSizes = {
    one: {
        regularWidth: 934,
        mediumWidth: 423,
        mediumHeight: 0,
        smallWidth: 767,
        smallHeight: 0,
    },
    two: {
        regularWidth: 454,
        mediumWidth: 423,
        mediumHeight: 0,
        smallWidth: 767,
        smallHeight: 0,
    },
}

export type Alignment = 'center' | 'top' | 'bottom'

export interface Image {
    url: string
    alt?: string
    mobileUrl?: string
}

export interface Props {
    style: LayoutStyle
    heading?: string
    headingElement?: HeadingElement
    headingVariant?: HeadingVariant
    content?: string | RichTextBlock[]
    videoId?: string
    images?: Image[]
    desktopMediaPosition?: string
    mobileMediaPosition?: string
    buttonUrl?: string
    buttonText?: string
    buttonStyle?: ButtonVariant
    button2Url?: string
    button2Text?: string
    button2Style?: ButtonVariant
    alignment?: Alignment
    withOffset?: boolean
    headingFuss?: ComponentProps<typeof Heading>['fuss']
    blockBackground?: { url?: string }
}

const Section = styled.section`
    background: var(--background-override, ${theme.colors.background});
    background-size: cover;
`

const MediaImage = ({
    image,
    sizes,
}: {
    image: Image
    sizes: {
        regularWidth?: number
        regularHeight?: number
        mediumWidth?: number
        mediumHeight?: number
        smallWidth?: number
        smallHeight?: number
    }
}) => (
    <Picture
        breakpoints={{
            [breakpoints.xs]: {
                url: image.mobileUrl ?? image.url,
                width: sizes.smallWidth ?? 767,
                height: sizes.smallHeight,
            },
            [breakpoints.md]: {
                url: image.mobileUrl ?? image.url,
                width: sizes.mediumWidth ?? 423,
                height: sizes.mediumHeight,
            },
            [breakpoints.lg]: {
                url: image.mobileUrl ?? image.url,
                width: sizes.regularWidth ?? 934,
                height: sizes.regularHeight,
            },
        }}
        lazy
        alt={image.alt ?? ''}
        className={styles.imageAuto}
    />
)

const TextWithMedia = ({
    style,
    heading,
    headingElement = HeadingElement.h2,
    headingVariant = HeadingVariant.h2,
    content,
    images,
    desktopMediaPosition = 'right',
    mobileMediaPosition = 'top',
    videoId,
    buttonUrl,
    buttonText,
    buttonStyle,
    button2Url,
    button2Text,
    button2Style,
    alignment,
    withOffset = true,
    headingFuss,
    blockBackground,
}: Props) => {
    const { isMobile } = useBreakpoints()
    const layoutStyle = getLayoutStyleVars(style)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (blockBackground?.url) {
            ref.current?.style.setProperty(
                '--background-override',
                `url(${blockBackground.url})`,
            )
        }
    }, [blockBackground])

    if (!images || images.length === 0 || !heading) {
        return null
    }
    const desktopPositionRight = desktopMediaPosition === 'right'
    const mobilePositionTop = mobileMediaPosition === 'top'

    const imageOne = images[0]
    const imageTwo = images[1]

    // Keep style dependent buttonVariant for backwards compatibility
    const buttonVariant: ButtonVariant =
        buttonStyle ||
        (style === 'light' ? ButtonVariant.primary : ButtonVariant.white)

    const button2Variant: ButtonVariant =
        button2Style || ButtonVariant.secondary

    return (
        <Section
            ref={ref}
            className={cx(styles.wrapper, {
                [styles.textLeft]: desktopPositionRight,
                [styles.textRight]: !desktopPositionRight,
                [styles.imageTop]: mobilePositionTop,
            })}
            css={layoutStyle}
        >
            <Container>
                <Row className={styles.row}>
                    <Col
                        xs={{
                            order: mobilePositionTop ? 2 : 0,
                            span: 12,
                            offset: 0,
                        }}
                        md={{
                            order: desktopPositionRight ? 0 : 1,
                            span: 6,
                            offset: 0,
                        }}
                        lg={{
                            order: desktopPositionRight ? 0 : 1,
                            span: withOffset ? 3 : 4,
                            offset: 1,
                        }}
                        className={cx(styles.content, styles.rowTwoContent, {
                            [styles.centerAlign]: alignment === 'center',
                            [styles.bottomAlign]: alignment === 'bottom',
                        })}
                    >
                        <Heading
                            variant={headingVariant}
                            element={headingElement}
                            className={styles.heading}
                            fuss={headingFuss}
                        >
                            {heading}
                        </Heading>

                        {content && (
                            <>
                                {typeof content === 'string' ? (
                                    <Text className={styles.text}>
                                        {content}
                                    </Text>
                                ) : (
                                    <PrismicRichText
                                        render={content as [RichTextBlock]}
                                        components={getPrismicComponents({
                                            contentClassName: cx(
                                                styles.text,
                                                style
                                                    ? style === 'dark'
                                                        ? styles.lightText
                                                        : styles.darkText
                                                    : '',
                                            ),
                                        })}
                                        analyticsContext="prismic.textwithimage.content"
                                    />
                                )}
                            </>
                        )}
                        <div>
                            {buttonText && buttonUrl && (
                                <Button
                                    wide={isMobile}
                                    minWidth={!isMobile}
                                    to={buttonUrl}
                                    variant={buttonVariant}
                                    className={styles.button}
                                    name={buttonText}
                                    category="prismic.textwithimage.button"
                                >
                                    {buttonText}
                                </Button>
                            )}
                            {button2Text && button2Url && (
                                <Button
                                    wide={isMobile}
                                    minWidth={!isMobile}
                                    to={button2Url}
                                    variant={button2Variant}
                                    className={styles.button}
                                    name={button2Text}
                                    category="prismic.textwithimage.button2"
                                >
                                    {button2Text}
                                </Button>
                            )}
                        </div>
                    </Col>
                    <Col
                        xs={{ order: 1, span: 12, offset: 0 }}
                        md={{
                            order: desktopPositionRight ? 1 : 0,
                            span: 6,
                            offset: 0,
                        }}
                        lg={{
                            order: desktopPositionRight ? 1 : 0,
                            span: 6,
                            offset:
                                !withOffset && !desktopPositionRight ? 0 : 1,
                        }}
                        className={styles.image}
                    >
                        {videoId && (
                            <Video
                                url={`https://player.vimeo.com/video/${videoId}`}
                                width="100%"
                                responsive
                                muted
                                background
                                loop
                                autoplay
                            />
                        )}

                        {!videoId && !imageTwo && (
                            <MediaImage
                                image={imageOne}
                                sizes={imageSizes.one}
                            />
                        )}

                        {!videoId && imageTwo && (
                            <Row>
                                <Col xs={12} lg={6}>
                                    <MediaImage
                                        image={imageOne}
                                        sizes={imageSizes.two}
                                    />
                                </Col>

                                <Responsive lg up>
                                    <Col xs={12} lg={6}>
                                        <MediaImage
                                            image={imageTwo}
                                            sizes={imageSizes.two}
                                        />
                                    </Col>
                                </Responsive>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default TextWithMedia
