import { CartItemFragment } from '@emico-hooks/cart-fragment'

export const getConfigurableAttributeValuesFromCartItemProduct = (
    cartItem: CartItemFragment,
) =>
    cartItem?.product &&
    cartItem.configurableOptions.reduce((memo, opt) => {
        const optionValueUid = opt.configurableProductOptionValueUid
        const findLabel =
            'configurableOptions' in cartItem.product!
                ? cartItem.product.configurableOptions?.find((opt) =>
                      opt.values.find((val) => val.uid === optionValueUid),
                  )?.attributeId
                : ''

        return findLabel
            ? {
                  ...memo,
                  [findLabel]: opt.configurableProductOptionValueUid,
              }
            : memo
    }, {})
