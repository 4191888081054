import { useReactiveVar } from '@apollo/client'
import cx from 'classnames'
import { UnregisterCallback } from 'history'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { headerSizeVar } from './HeaderContainer'
import styles from './MobileNavigation.module.scss'
import { SubNav } from './SubNav'
import useMenuItems from './useMenuItems'
import Portal from '../../Portal'

interface Props {
    close(): void
    open?: boolean
}

const MobileNavigation = ({ close, open }: Props) => {
    const headerSize = useReactiveVar(headerSizeVar)
    const history = useHistory()

    const menuItems = useMenuItems()

    const closeMenu = useCallback(() => {
        close()
    }, [close])

    useEffect(() => {
        let unsubscribe: UnregisterCallback
        if (open) {
            unsubscribe = history.listen((_) => {
                closeMenu()
            })
        }

        return () => {
            unsubscribe?.()
        }
    }, [closeMenu, history, open])

    if (!menuItems || menuItems.length === 0) {
        return null
    }

    return (
        <>
            <nav className={styles.hidden}>
                <SubNav
                    items={menuItems}
                    onItemClick={closeMenu}
                    extendedMenu
                />
            </nav>

            <Portal>
                <div
                    className={cx(styles.modal, open ? styles.open : '')}
                    style={{
                        top: headerSize?.height || 0,
                    }}
                >
                    <div className={styles.modalBody}>
                        <SubNav
                            items={menuItems}
                            onItemClick={closeMenu}
                            className={styles.subNav}
                            extendedMenu
                        />
                    </div>
                </div>
            </Portal>
        </>
    )
}

export default MobileNavigation
