import { ReactNode } from 'react'

import { useCoreConfig } from './coreConfig.query'

export const AutoLoader = ({ children }: { children: ReactNode }) => {
    const { loading } = useCoreConfig()

    if (loading) {
        return null
    }

    return <>{children}</>
}
