import { useActiveStoreView } from '@emico/storeviews'

import { PRIVILEGES_MENU_WHITELIST } from '../../constants'

export function storeHasPrivilegesMenuItem(storeCode: string): boolean {
    return Boolean(PRIVILEGES_MENU_WHITELIST?.includes(storeCode))
}

export function useHasPrivilegesMenuItem() {
    const storeView = useActiveStoreView()

    return storeHasPrivilegesMenuItem(storeView.code)
}
