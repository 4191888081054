// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ComponentProps } from 'react'

import { usePrismicDocuments } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'

import { MemberUsps as Usp } from './graphql/schema.generated'
import { mapVariant } from './mapHeadingVariant'
import MemberUsps from './MemberUsps'
import ButtonVariant from './types/ButtonVariant'

export type MemberUspsType = 'login' | 'order confirmation'

const useMemberUspData = (type: MemberUspsType) => {
    const activeStoreView = useActiveStoreView()

    const { data, loading } = usePrismicDocuments<Usp | undefined>(
        {
            key: 'my.member_usps.storeview',
            values: [activeStoreView.prismic || 'pme'],
        },
        true,
    )

    if (loading) {
        return { data: undefined, loading }
    }

    if (!data || data.length === 0) {
        return { data: undefined, loading: false }
    }

    // Find the usp for the current type
    const usp = data.find((b) => b?.data?.type === type)?.data

    if (!usp) {
        return { data: undefined, loading: false }
    }

    // Get all required data from the usp
    const {
        heading,
        headingVariant,
        content,
        buttonText,
        buttonUrl,
        buttonStyle,
        button2Text,
        button2Url,
        button2Style,
        usps,
        showPriviligesLogo,
    } = usp

    // Filter null and undefined values
    const mappedUsps =
        usps
            ?.filter(({ label, icon }) => label && icon)
            .map(({ label, icon }) => ({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                label: label!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                icon: icon!,
                props: {},
            })) || []

    // Check for mandatory data
    if (!heading || !mappedUsps || mappedUsps.length === 0) {
        return { data: undefined, loading: false }
    }

    const memberUspData: ComponentProps<typeof MemberUsps> = {
        heading,
        headingVariant: mapVariant(headingVariant),
        content: content ?? undefined,
        buttonText: buttonText ?? undefined,
        buttonUrl: buttonUrl ?? undefined,
        buttonStyle: (buttonStyle as ButtonVariant) ?? undefined,
        button2Text: button2Text ?? undefined,
        button2Url: button2Url ?? undefined,
        button2Style: (button2Style as ButtonVariant) ?? undefined,
        usps: mappedUsps,
        showPriviligesLogo: showPriviligesLogo ?? false,
    }

    return { memberUspData, loading }
}

export default useMemberUspData
