import cx from 'classnames'
import * as React from 'react'
import { snakeCase } from 'snake-case'

import styles from './RecommendedProducts.module.scss'
import { LazyProductPage } from '../../chunks'
import ProductCard, {
    ProductCardThemesType,
} from '../../presentation/ProductCard'
import ProductCardWithAddToCart from '../../ProductCardWithAddToCart'
import Carousel from '../../RecommendedProducts/Carousel'
import Heading from '../../typography/Heading'
import useProductsBySku from '../../useProductsBySku'
import useSelectItem from '../../utils/ga4/useSelectItem'
import { TweakwiseNavigateItem } from '../common/ProductFilterPage/TweakwiseNavigate.query'
import { useGetProduct } from '../ProductPage/GetProduct.query'
import {
    TweakwiseRecommendationsType,
    useTweakwiseRecommendations,
} from '../TweakwiseRecommendations.query'

interface Props
    extends React.HTMLAttributes<HTMLDivElement>,
        Pick<React.ComponentProps<typeof ProductCard>, 'position'> {
    header: string
    recommendationsType: TweakwiseRecommendationsType
    templateId?: number
    productId?: number
    overrule?: boolean
    blueConicSkus?: string[]
    withSizeSelect?: boolean
    itemListSlot: string
}

const RecommendedProducts = ({
    recommendationsType,
    header,
    templateId,
    productId,
    className,
    overrule,
    blueConicSkus,
    withSizeSelect = true,
    position,
    itemListSlot,
    ...htmlProps
}: Props) => {
    const selectItem = useSelectItem()
    const { data: parentProduct } = useGetProduct(productId)
    let recommendedProductsArray: string[] = parentProduct?.recommendations
        ? parentProduct?.recommendations?.split(/[:;,|]+/)
        : []

    recommendedProductsArray = blueConicSkus
        ? blueConicSkus
        : !overrule
          ? []
          : recommendedProductsArray

    const { data: productsBySku } = useProductsBySku(recommendedProductsArray, {
        skip: recommendedProductsArray.length === 0,
    })

    // Tweakwise recommendations call can be skipped when blueconic data is provided
    const { data: products, loading } = useTweakwiseRecommendations(
        recommendationsType,
        productId,
        templateId?.toString(),
        blueConicSkus !== undefined,
    )

    const productsArray = blueConicSkus
        ? productsBySku
        : (products || []).concat(productsBySku || [])

    const handleProductClick = (product: TweakwiseNavigateItem) => {
        selectItem(product, {
            item_list_id: snakeCase(header),
            item_list_name: header,
            index: productsArray ? productsArray.indexOf(product) + 1 : 0,
        })
    }

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }
    // endregion

    // When loading or when there are no recommendations just hide the entire panel
    if (loading || !productsArray || productsArray.length === 0) {
        return null
    }

    const Card = withSizeSelect ? ProductCardWithAddToCart : ProductCard

    return (
        <div {...htmlProps} className={cx(styles.default, className)}>
            <Heading
                variant="h2"
                element="h2"
                className={styles.heading}
                fuss={['pme']}
            >
                {header}
            </Heading>
            <Carousel className={styles.carousel}>
                {productsArray.map((product, index) => (
                    <Card
                        className={styles.slideWrapper}
                        key={product.urlKey}
                        product={product}
                        theme={ProductCardThemesType.shaded}
                        withStockStatus
                        withColorSwatch
                        onMouseEnter={handleMouseEnter}
                        onClick={() => handleProductClick(product)}
                        position={position}
                        itemListName="Recommended Products"
                        itemListId="recommended_products"
                        itemListSlot={itemListSlot}
                        itemIndex={index}
                    />
                ))}
            </Carousel>
        </div>
    )
}

export default RecommendedProducts
