import * as React from 'react'
import { Route } from 'react-router-dom'

// NOTE: When defining or renaming routes, please make sure the backend configuration (backend/app/etc/config.php) is
// also updated. Failure to do so will cause a 404 Status Code on your new / renamed route.
import {
    LazyAccountDashboard,
    LazyCartPage,
    LazyCreatePassword,
    LazyForgotPassword,
    LazyLogin,
    LazyOutfitsPage,
    LazyPrismicPreviewResolver,
    LazyPriviligesPage,
    LazyRegister,
    LazySearchPage,
    LazySetPassword,
    LazySiteMap,
    LazyStoreLocatorDetailsPage,
    LazyStoreLocatorPage,
    LazyWishlistPage,
} from './chunks'
import {
    PERSONAL_SHOPPER_ENABLED,
    PRIVILEGES_ENABLED,
    STORELOCATOR_DISABLED,
    STORES_DISABLED,
} from './constants'
import DynamicRouteResolver from './core/DynamicRouteResolver'
import paths from './paths'
import { routes as sitemapRoutes } from './sitemap'

export type RouteType = React.ComponentProps<typeof Route> & { path: string }

let routes: RouteType[] = [
    {
        path: paths.personalShopperOutfits(),
        component: LazyOutfitsPage,
    },
    {
        path: sitemapRoutes.siteMap,
        component: LazySiteMap,
    },
    {
        path: paths.login,
        component: LazyLogin,
    },
    {
        path: paths.register,
        component: LazyRegister,
    },
    {
        path: paths.forgotPassword,
        component: LazyForgotPassword,
    },
    {
        path: paths.setPassword,
        component: LazySetPassword,
    },
    {
        path: paths.activateAccount,
        component: LazyCreatePassword,
    },
    {
        path: paths.account,
        component: LazyAccountDashboard,
    },

    {
        path: paths.cartWithOptionalId,
        component: LazyCartPage,
    },
    {
        path: paths.wishlist,
        component: LazyWishlistPage,
    },
    // {
    //     path: paths.checkout,
    //     component: LazyCheckout,
    // },
    // {
    //     path: paths.checkoutDeliveryWithOptionalCartId,
    //     component: LazyCheckoutDeliveryPage,
    // },
    // {
    //     path: paths.checkoutDetailsWithOptionalCartId,
    //     component: LazyCheckoutContactDetailsPage,
    // },
    // {
    //     path: paths.checkoutPaymentWithOptionalCartId,
    //     component: LazyCheckoutPaymentPage,
    // },
    // {
    //     path: paths.checkoutPaymentPending,
    //     component: LazyCheckoutPaymentPendingPage,
    // },
    // {
    //     path: paths.checkoutSuccessWithToken,
    //     component: LazyCheckoutSuccessPage,
    // },
    {
        path: paths.search,
        component: LazySearchPage,
    },
    {
        path: paths.prismicPreview,
        exact: true,
        component: LazyPrismicPreviewResolver,
    },
    {
        path: paths.home,
        component: DynamicRouteResolver,
    },
]

if (PERSONAL_SHOPPER_ENABLED) {
    routes = [
        {
            path: paths.personalShopperOutfits(),
            component: LazyOutfitsPage,
        },
        ...routes,
    ]
}

if (!STORELOCATOR_DISABLED && !STORES_DISABLED) {
    routes = [
        {
            path: paths.storeLocatorDetails,
            component: LazyStoreLocatorDetailsPage,
        },
        {
            path: paths.storeLocator,
            component: LazyStoreLocatorPage,
        },
        ...routes,
    ]
}

if (PRIVILEGES_ENABLED) {
    routes = [
        { path: paths.privileges, component: LazyPriviligesPage },
        ...routes,
    ]
}

export default routes
