import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import cart, { CartState } from './__cart'
import checkout, { CheckoutState } from './__checkout'
import user, { UserState } from './user'

const persistConfig = {
    key: 'primary',
    storage,
    timeout: 0, // This is done so Pagespeed CPU idle is no longer at 7 seconds
}

export interface State {
    cart: CartState
    checkout: CheckoutState
    user: UserState
}

const combinedReducer = combineReducers({
    cart,
    checkout,
    user,
})

export default persistReducer(persistConfig, combinedReducer)
