import { ApolloError } from '@apollo/client'
import { CartItemFragment } from '@emico-hooks/cart-fragment'
import {
    ConfigurableOptionInput,
    useUpdateCartItems,
} from '@emico-hooks/cart-update-cart'
import { Trans } from '@lingui/macro'
import * as React from 'react'
import { Control, useForm } from 'react-hook-form'

import {
    CartItemConfigurableConfiguration,
    CartItemConfigurableFormValue,
} from './CartItemConfigurableConfiguration'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../../catalog/ProductPage/GetProduct.query'
import { encodeAttributeValueObject } from '../../catalog/ProductPage/StickyCta/flattenCombinedAttributes'
import { giftModalEnabledVar } from '../../FreeGiftAvailableModal'
import FormError from '../../input/FormError'
import { useAddToCartEvent } from '../../utils/ga4/useAddToCartEvent'
import {
    resolveConfigurableOptions,
    serializeConfigurableOptions,
} from '../CartItem/CartItem'
import styles from '../CartItem/CartItem.module.scss'
import CartItemQuantityForm from '../CartItemQuantityForm'

interface Props {
    cartItem: CartItemFragment
    product: Product | ConfigurableProduct
    disabled?: boolean
}

const CartItemConfiguration = ({ cartItem, product, disabled }: Props) => {
    const pushAddToCart = useAddToCartEvent()

    const updateCartItems = useUpdateCartItems()

    const [updatingCart, setUpdatingCart] = React.useState<boolean>(false)

    const [formError, setFormError] = React.useState<string>()

    const variant =
        'variants' in product
            ? product.variants
                  .filter((item) => item.product)
                  .find(
                      (item) =>
                          item.product?.id === cartItem.configuredVariant?.id,
                  )
            : null

    const getAttributeIdByCode = (code: string) => {
        if ('variants' in product) {
            return product.configurableOptions.find(
                (opt) => opt.attributeCode === code,
            )?.attributeId
        }
        return undefined
    }

    const attributeValue = encodeAttributeValueObject(
        cartItem?.configurableOptions?.reduce(
            (memo, curr) => ({
                ...memo,
                [getAttributeIdByCode(curr.optionLabel) ?? '']:
                    curr.configurableProductOptionValueUid,
            }),
            {},
        ),
    )

    const { handleSubmit, control } = useForm<CartItemConfigurableFormValue>({
        defaultValues: {
            value: attributeValue,
        },
    })

    const updateCartItem = async (
        quantity: number,
        options: ConfigurableAttributesFieldValue,
    ) => {
        if (quantity < 1) {
            return
        }
        setFormError('')

        const selectedOptions: ConfigurableOptionInput[] = []
        if ('variants' in product) {
            cartItem.configurableOptions.forEach((opt) => {
                const label = opt.optionLabel
                const confOption = product.configurableOptions.find(
                    (opt) => opt.label === label,
                )
                if (confOption) {
                    selectedOptions.push({
                        configurable_product_option_uid:
                            opt.configurableProductOptionUid,
                        configurable_product_option_value_uid: String(
                            options[Number(confOption.attributeId)],
                        ),
                    })
                }
            })
        }

        try {
            await updateCartItems([
                {
                    cart_item_uid: cartItem.uid,
                    configurable_options: selectedOptions,
                    quantity,
                },
            ])
            if (cartItem.product) {
                pushAddToCart(cartItem.product.sku, product, quantity, options)
            }
            giftModalEnabledVar(true)
        } catch (e: any) {
            if (e instanceof ApolloError) {
                const errorMessage = e.graphQLErrors?.[0]?.message
                setFormError(errorMessage)
            } else {
                throw new Error(e?.message)
            }
        }
    }

    return (
        <React.Fragment>
            {formError && (
                <div style={{ position: 'absolute', zIndex: 100 }}>
                    <FormError>{formError}</FormError>
                </div>
            )}
            <form
                onSubmit={handleSubmit(() => {
                    // no-op, updates are handled on-change
                })}
            >
                <CartItemConfigurableConfiguration
                    product={product}
                    control={
                        control as Control<
                            CartItemConfigurableFormValue,
                            'value'
                        >
                    }
                    onChange={async (value) => {
                        setUpdatingCart(true)
                        await updateCartItem(cartItem.quantity, value)
                        setUpdatingCart(false)
                    }}
                />
            </form>

            <span className={styles.amount}>
                <label htmlFor={`quantity-${cartItem.uid}`}>
                    <Trans id="cart.cartItem.amountLabel">Amount</Trans>
                </label>
                <CartItemQuantityForm
                    cartItemId={cartItem.uid}
                    quantity={cartItem.quantity}
                    max={
                        cartItem.quantity >
                        (variant?.product?.onlyXLeftInStock ?? 0)
                            ? cartItem.quantity
                            : variant?.product?.onlyXLeftInStock ?? 0
                    }
                    onSubmit={async (values) => {
                        if (
                            values.quantity === cartItem.quantity ||
                            values.quantity < 1
                        ) {
                            return
                        }
                        setUpdatingCart(true)

                        const selectedOptions =
                            (product as ConfigurableProduct)
                                .configurableOptions &&
                            resolveConfigurableOptions(
                                cartItem.configurableOptions.map((option) => ({
                                    label: option.optionLabel,
                                    value: option.configurableProductOptionValueUid,
                                })),
                                product as ConfigurableProduct,
                            )

                        const options = selectedOptions
                            ? serializeConfigurableOptions(selectedOptions)
                            : {}

                        await updateCartItem(values.quantity, options)
                        setUpdatingCart(false)
                    }}
                    disabled={updatingCart || disabled}
                />
            </span>
        </React.Fragment>
    )
}

export default CartItemConfiguration
