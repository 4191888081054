import React, { useEffect } from 'react'
import { Redirect, Switch, useHistory, useParams } from 'react-router-dom'

import LoginPage from './LoginPage'
import { useCartItems } from '../cart/CartPage/useCartItems'
import paths from '../paths'
import { RouteType } from '../routes'
import { SentryRoute } from '../Routing'

export const exactPath = '/checkout'
export const nestedPath = '/checkout/:page'

const lazyDeliveryPage = React.lazy(() => import('./DeliveryPage'))
const lazyContactDetailsPage = React.lazy(() => import('./ContactDetailsPage'))
const lazyPaymentPage = React.lazy(() => import('./PaymentPage'))
const lazyPaymentPendingPage = React.lazy(() => import('./PaymentPendingPage'))
const lazyPaymentSuccessPage = React.lazy(() => import('./SuccessPage'))

const checkoutNestedRoutes: RouteType[] = [
    { path: `${exactPath}/delivery`, component: lazyDeliveryPage },
    { path: `${exactPath}/contact-details`, component: lazyContactDetailsPage },
    { path: `${exactPath}/payment`, component: lazyPaymentPage },
    { path: `${exactPath}/payment-pending`, component: lazyPaymentPendingPage },
    { path: `${exactPath}/success/:token`, component: lazyPaymentSuccessPage },
]

const CheckoutRouter = () => {
    const { page } = useParams<{ page: string }>()
    const history = useHistory()

    const { totalQuantity, loading: isLoadingCart } = useCartItems()

    useEffect(() => {
        if (
            !isLoadingCart &&
            totalQuantity !== undefined &&
            totalQuantity < 1
        ) {
            history.replace(paths.cart)
        }
    }, [history, isLoadingCart, totalQuantity])

    return (
        <React.Suspense fallback={null}>
            {page ? (
                <Switch>
                    {checkoutNestedRoutes.map((route) => (
                        <SentryRoute key={route.path} {...route} />
                    ))}
                </Switch>
            ) : (
                <LoginPage />
            )}
        </React.Suspense>
    )
}

export default CheckoutRouter
