/* eslint-disable @typescript-eslint/ban-ts-comment */
import getImgProxyUrl from '@emico-react/image/src/getImgProxyUrl'
import styled from '@emotion/styled'

import { Maybe, PrismicImageType } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'

const Grid = styled.div`
    max-width: 100%;
    margin: 0 auto;
    background-size: cover;

    @media ${minWidth('md')} {
        display: grid;
        // On portrait tablet, use full with of the screen
        // and use a 3 column layout
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;

        // Direct children are the grid cells
        // By default, they should span the whole row
        > * {
            grid-column: span 3;
        }
    }

    @media ${minWidth('lg')} {
        grid-template-columns: repeat(4, 1fr);

        > * {
            grid-column: span 4;
        }
    }
`

interface Props {
    children: React.ReactNode
    pageBackground?: Maybe<PrismicImageType>
    pageBackgroundColor?: 'dark' | 'light' | null
}

const PrismicGrid = ({
    children,
    pageBackground,
    pageBackgroundColor,
}: Props) => (
    <Grid
        style={
            pageBackground?.url
                ? {
                      // @ts-expect-error
                      '--background-override': 'none',
                      '--text-color-override':
                          pageBackgroundColor === 'dark' ? '#fff' : '#000',
                      backgroundImage: `url(${getImgProxyUrl(pageBackground.url, { width: 1920 })})`,
                  }
                : {}
        }
    >
        {children}
    </Grid>
)

export default PrismicGrid
