import { ComponentProps } from 'react'

import BannerBlocks, { BannerBlocksItem } from './BannerBlocks'
import {
    CastironPageBodyBlog,
    JbfoPageBodyBlog,
    JustbrandsPageBodyBlog,
    PageBodyBlog,
    VanguardPageBodyBlog,
} from './graphql/schema.generated'
import styles from './PrismicSliceBlog.module.scss'
import Heading from './typography/Heading'
import Text from './typography/Text'
import getLayoutStyle from './utils/getLayoutStyle'

interface Props {
    slice:
        | PageBodyBlog
        | VanguardPageBodyBlog
        | JbfoPageBodyBlog
        | CastironPageBodyBlog
        | JustbrandsPageBodyBlog
}

export const PrismicSliceBlog = ({ slice: { primary, fields } }: Props) => {
    const style = primary?.blogStyle
    const theme = getLayoutStyle(style) === 'dark' ? 'light' : 'dark'

    const bannerBlocks: BannerBlocksItem[] = (fields ?? []).reduce<
        BannerBlocksItem[]
    >((acc, field, idx) => {
        const { header, desktopImage, url, mobileImage } = field

        acc.push({
            children: (
                <div className={styles.blogContents} key={`${header}_${idx}`}>
                    <Heading
                        element="h2"
                        variant="h2"
                        color={theme}
                        className={styles.header}
                    >
                        {header ?? ''}
                    </Heading>
                    {field?.subHeader && (
                        <Text color={theme} italic>
                            {field?.subHeader}
                        </Text>
                    )}
                    <Text color={theme}>{field?.blogText}</Text>
                </div>
            ),
            title: undefined,
            url: url ?? undefined,
            image: desktopImage?.url
                ? {
                      url: desktopImage.url,
                      alt: desktopImage.alt ?? header ?? '',
                  }
                : undefined,
            mobileImage: mobileImage?.url
                ? {
                      url: mobileImage.url,
                      alt: mobileImage.alt ?? header ?? '',
                  }
                : undefined,
        })
        return acc
    }, [])
    //bannerBlocks = bannerBlocks.concat(bannerBlocks)

    const componentProps: ComponentProps<typeof BannerBlocks> = {
        style: getLayoutStyle(style),
        title: primary?.title ?? '',
        bannerBlocks,
        amount: fields?.length,
        slidesToShow: 4,
        buttonVariant: undefined,
        className: styles.blogEntry,
        linkVariant: 'camouflaged',
    }

    return <BannerBlocks {...componentProps} />
}
