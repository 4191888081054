import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import paths from './paths'
import useIsCheckoutAccessible from './useIsCheckoutAccessible'
import { isLoggingIn } from './useLoginForm'

const useStockCheck = () => {
    const { push } = useHistory()
    const isCheckoutAccessible = useIsCheckoutAccessible()
    const isLoginPending = useReactiveVar(isLoggingIn)

    useEffect(() => {
        if (!isLoginPending && !isCheckoutAccessible) {
            push(paths.cart)
        }
    }, [isCheckoutAccessible, push, isLoginPending])
}

export default useStockCheck
