import { Image } from '@emico-react/image'
import cx from 'classnames'

import styles from './CartItemImage.module.scss'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { LazyProductPage } from '../chunks'
import { Maybe, ProductImage } from '../graphql/schema.generated'
import Link from '../navigation/Link'
import overridable from '../utils/overridable'

interface Props {
    product: Overwrite<
        Pick<Product, 'urlKey' | 'name' | 'smallImage' | 'modelImage'>,
        {
            smallImage: Maybe<Pick<ProductImage, 'url'>>
        }
    >
    variant?: 'standard' | 'gift'
    disabled?: boolean
}

const CartItemImage = ({ product, variant = 'standard', disabled }: Props) => {
    const modelImage = product.modelImage === '1' ? true : false

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    return (
        <figure
            className={cx(styles.imageContainer, {
                [styles.gift]: variant === 'gift',
                [styles.disabled]: disabled,
            })}
        >
            <Link
                to={`/${product.urlKey}`}
                name={product.name}
                category="cart.cartPage.cartItem"
                onMouseEnter={handleMouseEnter}
            >
                <Image
                    alt={product.name}
                    url={product.smallImage?.url ?? ''}
                    sizes={{
                        sm: 100,
                        md: 100,
                        lg: 300,
                    }}
                    lazy
                    className={cx(styles.image, {
                        [styles.modelImage]: modelImage,
                    })}
                />
            </Link>
        </figure>
    )
}

export default overridable('CartItemImage')(CartItemImage)
