import { useActiveStoreView } from '@emico/storeviews'

import { PRIVILIGES_DASHBOARD_WHITELIST } from '../../constants'

export function storeHasPriviligesDashboard(storeCode: string): boolean {
    return Boolean(PRIVILIGES_DASHBOARD_WHITELIST?.includes(storeCode))
}

export function useHasPriviligesEnabled() {
    const storeView = useActiveStoreView()

    return storeHasPriviligesDashboard(storeView.code)
}
