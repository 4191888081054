import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${colorVarKeys.grayDark}: #141414;
                ${colorVarKeys.primary}: #eda600;
                ${colorVarKeys.primaryExtra}: #eab942;
                ${colorVarKeys.secondary}: #003264;
                ${colorVarKeys.secondaryExtra}: #003264;

                ${colorVarKeys.footerTopBackground}: #1d1d1d;
                ${colorVarKeys.footerAccordionBorder}: var(${colorVarKeys.footerTopBackground});

                ${colorVarKeys.footerDivider}: var(${colorVarKeys.footerTopBackground});

                ${colorVarKeys.badgeBg}: #eda600;

                ${typographyVarKeys.fonts.primary}: 'Univers LT Std', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts
                    .secondary}: 'Univers LT Std Condensed', Helvetica, Arial,
                    sans-serif;

                --visual-filter-label-padding: 0.5em 1em 0.2em;

                --membercard-bg-color: var(${colorVarKeys.grayDark});
                --membercard-logo-color: var(${colorVarKeys.primary});
                --membercard-bar-color: var(${colorVarKeys.grayDark});
            }
        `}
    />
)

export default ThemeOverrides
