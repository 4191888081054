import lazy from '@emico/lazy'

// All chunks are here so that lazy can properly preload any chunks that
// are needed during the initial rendering of the page before the page is
// rendered. This avoids a white-flash during initial render.
// This also gives us an easy entry point for chunk preloading.

// storelocator
export const LazyStoreLocatorPage = lazy(
    'storelocatorpage',
    () =>
        import(
            /* webpackChunkName: "storelocatorpage" */
            './storelocator/StoreLocatorPage'
        ),
)

export const LazyStoreLocatorDetailsPage = lazy(
    'storelocatorpage',
    () =>
        import(
            /* webpackChunkName: "storelocatorpage" */
            './storelocator/StoreLocatorDetailsPage'
        ),
)

export const LazyProductPage = lazy(
    'product',
    () =>
        import(
            /* webpackChunkName: "product" */
            './catalog/ProductPage'
        ),
)
export const LazySizeGuideModal = lazy(
    'SizeGuideModal',
    () =>
        /* webpackChunkName: "product" */
        import('./presentation/SizeGuideModal/SizeGuideModal'),
)

// catalog
export const LazyCategoryPage = lazy(
    'catalog',
    () =>
        import(
            /* webpackChunkName: "catalog" */
            './catalog/CategoryPage'
        ),
)
export const LazySearchPage = lazy(
    'catalog',
    () =>
        import(
            /* webpackChunkName: "catalog" */
            './catalog/SearchPage'
        ),
)

export const LazyWishlistPage = lazy(
    'WishListPage',
    () =>
        import(
            /* webpackChunkName: "WishListPage" */
            './wishlist/WishListPage'
        ),
)

// Desktop filtering
export const TweakwiseFilterInput = lazy(
    'TweakwiseFilterInput',
    () =>
        import(
            /* webpackChunkName: "TweakwiseFilterInput" */
            './catalog/common/ProductFilterPage/FilterBar/TweakwiseFilterInput'
        ),
)
// Mobile filtering
export const FilterModal = lazy(
    'FilterModal',
    () =>
        import(
            /* webpackChunkName: "FilterModal" */
            './catalog/common/ProductFilterPage/FilterBar/FilterBarMobile/FilterModal'
        ),
)

// cms
export const CmsPage = lazy(
    'cms',
    () =>
        import(
            /* webpackChunkName: "cms" */
            './CmsPage'
        ),
)

export const HomePageLayout = lazy(
    'HomePageLayout',
    () =>
        import(
            /* webpackChunkName: "HomePageLayout" */
            './cms-pages/HomePage/HomePageLayout'
        ),
)
export const HtmlPageLayout = lazy(
    'HtmlPageLayout',
    () =>
        import(
            /* webpackChunkName: "HtmlPageLayout" */
            './cms-pages/HtmlPage/HtmlPageLayout'
        ),
)
export const JBFOHomePageLayout = lazy(
    'JBFOHomePageLayout',
    () =>
        import(
            /* webpackChunkName: "JBFOHomePageLayout" */
            './cms-pages/JBFOHomePage/JBFOHomePageLayout'
        ),
)
export const JustBrandsHomePageLayout = lazy(
    'JustBrandsHomePageLayout',
    () =>
        import(
            /* webpackChunkName: "JustBrandsHomePageLayout" */
            './cms-pages/JustBrandsHomePage/JustBrandsHomePageLayout'
        ),
)
export const StoriesOverviewLayout = lazy(
    'StoriesOverviewLayout',
    () =>
        import(
            /* webpackChunkName: "StoriesOverviewLayout" */
            './cms-pages/StoriesOverview/StoriesOverviewLayout'
        ),
)
export const StoryArticleLayout = lazy(
    'StoryArticleLayout',
    () =>
        import(
            /* webpackChunkName: "StoryArticleLayout" */
            './cms-pages/StoryArticle/StoryArticleLayout'
        ),
)

// cart
export const LazyCartPage = lazy(
    'cart',
    () =>
        import(
            /* webpackChunkName: "cart" */
            './cart/CartPage'
        ),
)

// // checkout
// export const LazyCheckout = lazy(
//     'checkout',
//     () =>
//         import(
//             /* webpackChunkName: "checkout" */
//             './checkout/LoginPage'
//         ),
// )

// export const LazyCheckoutDeliveryPage = lazy(
//     'checkout',
//     () => import('./checkout/DeliveryPage'),
// )
// export const LazyCheckoutContactDetailsPage = lazy(
//     'checkout',
//     () => import('./checkout/ContactDetailsPage'),
// )
// export const LazyCheckoutPaymentPage = lazy(
//     'checkout',
//     () => import('./checkout/PaymentPage'),
// )
// export const LazyCheckoutPaymentPendingPage = lazy(
//     'checkout',
//     () =>
//         import(
//             /* webpackChunkName: "checkout" */
//             './checkout/PaymentPendingPage'
//         ),
// )
// export const LazyCheckoutSuccessPage = lazy(
//     'checkout',
//     () =>
//         import(
//             /* webpackChunkName: "checkout" */
//             './checkout/SuccessPage'
//         ),
// )

// Fixes weird issue with css import order.
export const LazyRegister = lazy(
    'checkout',
    () =>
        import(
            /* webpackChunkName: "checkout" */
            './AccountRegisterPageLayout'
        ),
)
export const LazyLogin = lazy(
    'checkout',
    () =>
        import(
            /* webpackChunkName: "checkout" */
            './AccountLoginPageLayout'
        ),
)

// sitemap
export const LazySiteMap = lazy(
    'cart',
    () =>
        import(
            /* webpackChunkName: "sitemap" */
            './sitemap/SiteMap'
        ),
)

export const LazyForgotPassword = lazy(
    'user',
    () =>
        import(
            /* webpackChunkName: "user" */
            './account-forgotpassword-page/AccountForgotPasswordPageLayout'
        ),
)
export const LazySetPassword = lazy(
    'user',
    () =>
        import(
            /* webpackChunkName: "user" */
            './account-password/setPassword/SetPassword'
        ),
)
export const LazyCreatePassword = lazy(
    'user',
    () =>
        import(
            /* webpackChunkName: "user" */
            './account-password/createPassword/CreatePassword'
        ),
)
export const LazyAccountDashboard = lazy(
    'checkout',
    () =>
        import(
            /* webpackChunkName: "checkout" */
            './account-dashboard-page/AccountDashboardPageLayout/AccountDashboardPageLayout'
        ),
)

export const LazyOutfitsPage = lazy(
    'checkout',
    () =>
        import(
            /* webpackChunkName: "checkout" */
            './account-dashboard-page/AccountDashboardPageLayout/personalShopper/OutfitsPage'
        ),
)

export const LazyPrismicPreviewResolver = lazy(
    'prismic-preview',
    () =>
        import(
            /* webpackChunkName: "prismic-preview" */
            './PrismicPreviewResolver'
        ),
)

export const LazyPriviligesPage = lazy(
    'priviliges',
    () =>
        import(
            /* webpackChunkName: "priviliges" */
            './privileges/PrivilegesHomePage'
        ),
)
