import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { DataLayerProduct } from '@emico-utils/datalayer-templates'

import parseItemVariant from './parseItemVariant'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { getPrices } from '../../presentation/PriceBox'

export interface GA4ProductWithMeta {
    product: BasicProduct | BasicConfigurableProduct
    meta: DataLayerProduct
}

const toGA4Item = (
    productInput:
        | (BasicProduct | BasicConfigurableProduct)
        | GA4ProductWithMeta
        | CartItemFragment,
    rootCategoryId: number,
    rest?: DataLayerProduct,
): DataLayerProduct & { item_color?: string } => {
    const product =
        'product' in productInput ? productInput.product : productInput
    const meta = 'meta' in productInput ? productInput.meta : {}
    const configuredVariant =
        'configuredVariant' in productInput
            ? productInput.configuredVariant
            : undefined

    if (!product) {
        return {}
    }

    const prices = getPrices(product)

    const { sku, name, brand } = product

    const data: DataLayerProduct = {
        item_id: sku,
        item_sku: configuredVariant?.sku ?? sku,
        item_name: name.toLowerCase(),
        item_brand: brand,
        item_color: product.baseColorValues?.[0]?.label,
    }

    if (prices?.actualPrice?.value) {
        data.price = prices.actualPrice.value
        data.currency = prices.actualPrice.currency
    }

    if ('quantity' in productInput) {
        data.quantity = productInput.quantity
    }

    if (
        'configuredVariant' in productInput &&
        'variants' in (productInput.product ?? {})
    ) {
        data.item_variant = parseItemVariant(
            productInput.configurableOptions
                .map((v) => `${v.optionLabel}:${v.valueLabel}`)
                .join(','),
        )
    }

    const mainCategoryIds: string[] = []
    if (product.mainCategory?.id) {
        mainCategoryIds.push(product.mainCategory.id.toString())
    }
    product.mainCategory?.breadcrumbs?.forEach((cat) => {
        if (cat.categoryId) {
            mainCategoryIds.push(cat.categoryId.toString())
        }
    })
    mainCategoryIds.reverse().forEach((id, index) => {
        data[`item_category${index ? index + 1 : ''}`] = id
    })

    return { ...meta, ...data, ...rest }
}

export default toGA4Item
