import useCartItems from './useCartItems'
import { usePushEcommerceEvent } from './usePushEvent'

const useBeginCheckoutEvent = () => {
    const { items, prices } = useCartItems()

    const subTotal = prices?.subtotalIncludingTax?.value
    const currencyCode = prices?.grandTotal?.currency?.toString()

    const push = usePushEcommerceEvent([], false)

    const pushEvent = () => {
        if (!subTotal || !items) {
            return
        }
        push?.([
            { ecommerce: null },
            {
                event: 'begin_checkout',
                ecommerce: {
                    currency: currencyCode || 'EUR',
                    value: subTotal || 0,
                    items,
                },
            },
        ])
    }

    return pushEvent
}

export default useBeginCheckoutEvent
