import { ApolloClient, gql, useApolloClient, useQuery } from '@apollo/client'
import {
    getCustomerTokenHeaderObject,
    useAuthorizationContext,
} from '@emico-hooks/login-token'
import {
    getCacheableContext,
    stripMaybes,
} from '@emico-utils/graphql-data-utils'
import { useEffect, useState } from 'react'

import { wishlistIdVar } from './useWishlistId'
import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from '../catalog/common/MinimalConfigurableProductInfo.fragment'
import { BasicConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import {
    BasicProduct,
    GetBasicProductData,
    GetBasicProductVariables,
    basicProductQuerty,
} from '../catalog/ProductPage/GetBasicProduct.query'
import {
    ConfigurableWishlistItem as WishlistItemInterfaceType,
    Maybe,
    QueryWishlistV2Args,
    Wishlist as WishlistType,
    WishlistItems as WishlistItemsType,
} from '../graphql/schema.generated'
import productCardFragment, {
    ProductCardFragment,
} from '../ProductCardFragment'

export const wishlistFragment = gql`
    fragment WishlistFields on Wishlist {
        id
        itemsCount
        itemsV2(currentPage: 1, pageSize: 100) {
            items {
                id
                product {
                    id
                }
                ... on ConfigurableWishlistItem {
                    configurableOptions {
                        id
                        configurableProductOptionUid
                        configurableProductOptionValueUid
                        optionLabel
                        valueId
                        valueLabel
                    }
                }
            }
        }
    }
`

const query = gql`
    query getWishlist($id: ID!) {
        wishlistV2(id: $id) {
            ...WishlistFields
        }
    }
    ${wishlistFragment}
`

type WishlistItemInterface = Overwrite<
    Pick<WishlistItemInterfaceType, 'id' | 'product' | 'configurableOptions'>,
    {
        product: Maybe<BasicProduct | BasicConfigurableProduct>
    }
>

type WishlistItems = Overwrite<
    Pick<WishlistItemsType, 'items'>,
    {
        items: Array<Maybe<WishlistItemInterface>>
    }
>

type Wishlist = Overwrite<
    Pick<WishlistType, 'id' | 'itemsCount' | 'itemsV2'>,
    {
        itemsV2: Maybe<WishlistItems>
    }
>

interface WishlistData {
    wishlistV2: Maybe<Wishlist>
}

export const getWishlist =
    (client: ApolloClient<unknown>) =>
    async (id: string, customerToken: string | false) =>
        client.query<WishlistData>({
            query,
            variables: {
                id,
            },
            context: customerToken
                ? getCustomerTokenHeaderObject(customerToken)
                : {},
            fetchPolicy: 'network-only',
        })

export const useGetWishlist = (withProducts = false) => {
    const wishlistId = wishlistIdVar()
    const authorizationContext = useAuthorizationContext()
    const client = useApolloClient()
    const [dataWithProducts, setDataWithProducts] =
        useState<Maybe<Wishlist>>(null)
    const [productsLoading, setProductsLoading] = useState(false)

    const { data, loading, ...others } = useQuery<
        WishlistData,
        Omit<QueryWishlistV2Args, 'store'>
    >(query, {
        variables: {
            id: wishlistId ?? '',
        },
        skip: !wishlistId,
        context: authorizationContext,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
    })

    useEffect(() => {
        if (withProducts) {
            setProductsLoading(true)
            const productsMap = new Map<
                number,
                ProductCardFragment | BasicConfigurableProduct
            >()

            const wishlistProductIds =
                data?.wishlistV2?.itemsV2?.items.map(
                    (item) => item?.product?.id,
                ) ?? []
            Promise.all(
                wishlistProductIds.filter(stripMaybes).map((productId) =>
                    client
                        .query<GetBasicProductData, GetBasicProductVariables>({
                            query: basicProductQuerty,
                            variables: {
                                id: productId,
                            },
                            context: getCacheableContext(),
                        })
                        .then((product) => {
                            productsMap.set(
                                product.data.product.id,
                                product.data.product,
                            )
                        }),
                ),
            ).then(() => {
                setDataWithProducts(
                    data?.wishlistV2
                        ? {
                              ...data?.wishlistV2,
                              itemsV2: {
                                  items:
                                      data?.wishlistV2?.itemsV2?.items
                                          .filter(stripMaybes)
                                          .map((item) => ({
                                              id: item.id,
                                              configurableOptions:
                                                  item.configurableOptions,
                                              product:
                                                  productsMap.get(
                                                      item?.product?.id ?? -1,
                                                  ) ?? null,
                                          })) ?? [],
                              },
                          }
                        : null,
                )
                setProductsLoading(false)
            })
        }
    }, [client, data?.wishlistV2, withProducts])

    return {
        ...others,
        loading: loading || productsLoading,
        data: withProducts ? dataWithProducts : data?.wishlistV2,
    }
}
