import { useCartId } from '@emico-hooks/cart-id'
import { ComponentType } from 'react'
import { useHistory } from 'react-router-dom'

import { useCartItems } from '../../cart/CartPage/useCartItems'
import paths from '../../paths'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function redirectIfCartIsEmpty<Props extends {}>(
    Component: ComponentType<Props>,
) {
    // eslint-disable-next-line react/display-name
    return (props: Props) => {
        const history = useHistory()

        const cartId = useCartId()
        const { hasItems, loading } = useCartItems()

        const isCartEmpty = !hasItems || !cartId

        if (!loading && isCartEmpty) {
            history.push(paths.cart)
            return null
        }

        return <Component {...props} />
    }
}
