import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

import { varKeys as typographyVarKeys } from './theme/typography'

export type LayoutStyle = 'light' | 'dark'

const disabled = css`
    ${typographyVarKeys.headingDecorationVisibility.before}: none;
    ${typographyVarKeys.headingDecorationVisibility.after}: none;
`

const enabled = css`
    ${typographyVarKeys.headingDecorationVisibility.before}: inline-block;
    ${typographyVarKeys.headingDecorationVisibility.after}: inline-block;
`

export const EnableHeadingDecoration = styled.div`
    display: contents;
    ${enabled}
`

export const DisableHeadingDecoration = styled.div`
    display: contents;
    ${disabled}
`

const getHeadingDecorationVisibility = (
    visible: boolean,
): SerializedStyles | undefined => (visible ? enabled : disabled)

export default getHeadingDecorationVisibility
