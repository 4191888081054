import { Trans } from '@lingui/macro'
import cx from 'classnames'

import { ImaginaryActions } from '@emico/ui'

import styles from './Categories.module.scss'
import Carousel from '../../../behaviour/Carousel'
import Button from '../../../input/Button'
import Col from '../../../layout/Col'
import Row from '../../../layout/Row'
import ImageLazy from '../../../media/ImageLazy'
import Link from '../../../navigation/Link'
import {
    CmsPageBlockCategories,
    CmsPageBlockCategoriesListItem,
} from '../../../types/CmsPages'
import Heading from '../../../typography/Heading'
import Text from '../../../typography/Text'
import { useFilterNullValues } from '../../../utils/filterNullValues'

interface Props extends CmsPageBlockCategories {
    headingClassName?: string
    contentClassName?: string
    contentInnerClassName?: string
}

const renderCategories = (list: CmsPageBlockCategoriesListItem[]) => {
    const categories = []

    // eslint-disable-next-line no-restricted-syntax
    for (const i in list) {
        // eslint-disable-next-line no-prototype-builtins
        if (!list.hasOwnProperty(i)) {
            continue
        }
        const category = list[i]
        const { link, image, imageMobile, title } = category
        const imageUrl = image?.url

        if (link && imageUrl) {
            categories.push(
                <div key={i} className={styles.slide}>
                    <Link
                        name={title ?? ''}
                        category="homepage.categories"
                        to={link}
                        preload
                    >
                        <ImageLazy
                            images={{
                                image: imageUrl,
                                image_mobile: imageMobile?.url ?? imageUrl,
                                regularWidth: 644,
                                regularHeight: 660,
                                mediumWidth: 414,
                                mediumHeight: 428,
                                smallWidth: 286,
                                smallHeight: 290,
                                action: ImaginaryActions.SMARTCROP,
                            }}
                            alt={title ?? ''}
                            className={styles.slideImage}
                        />
                    </Link>
                    <div className={styles.slideContent}>
                        {title && (
                            <Heading
                                className={styles.slideTitle}
                                variant="h1"
                                element="h2"
                                color="light"
                            >
                                {title}
                            </Heading>
                        )}
                        {link && (
                            <Button
                                minWidth
                                variant="primary"
                                to={link}
                                className={styles.transparentButton}
                                name="Shop Now"
                                category="core.homePage.categories.shopNow"
                                preload
                            >
                                <Trans id="cms.homePage.categories.shopNow">
                                    Shop Now
                                </Trans>
                            </Button>
                        )}
                    </div>
                </div>,
            )
        }
    }

    return categories
}

const Categories = ({
    header,
    content,
    buttonUrl,
    buttonText,
    list,
    headingClassName,
    contentClassName,
    contentInnerClassName,
}: Props) => {
    const listItems = useFilterNullValues(list)

    if (!listItems || listItems.length === 0) {
        return null
    }

    return (
        <Row className={styles.default} noGutters>
            <Col lg={3}>
                <div className={contentClassName}>
                    {header && (
                        <Heading
                            variant="h2"
                            element="h2"
                            color="light"
                            className={cx(headingClassName, styles.heading)}
                            fuss={['pme']}
                        >
                            {header}
                        </Heading>
                    )}

                    {content && (
                        <div className={contentInnerClassName}>
                            <Text color="light">{content}</Text>
                        </div>
                    )}

                    {buttonUrl && buttonText && (
                        <Button
                            minWidth
                            to={buttonUrl}
                            variant="primary"
                            className={styles.button}
                            name={buttonText}
                            category="cms.homePage.categories"
                            preload
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
            </Col>

            <Col lg={9} className={styles.carousel}>
                <Carousel>{renderCategories(listItems)}</Carousel>
            </Col>
        </Row>
    )
}

export default Categories
