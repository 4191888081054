import { useReactiveVar } from '@apollo/client'
import { useCart } from '@emico-hooks/cart'
import { useIsLoggedIn } from '@emico-hooks/login-token'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { useEffect, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'

import { minWidth } from '@emico/styles'

import { useHasPriviligesEnabled } from '../../account/dashboard/useHasPriviligesDashboard'
import styles from '../../AccountLoginPageLayout.module.css'
import AccountUsps from '../../AccountUsps'
import { useCartItems } from '../../cart/CartPage/useCartItems'
import CheckoutAccessForm from '../../CheckoutAccessForm'
import Container from '../../layout/Container'
import paths from '../../paths'
import Loader from '../../presentation/Loader'
import { isLoggingIn } from '../../useLoginForm'
import useStockCheck from '../../useStockCheck'
import useBeginCheckoutEvent from '../../utils/ga4/useBeginCheckoutEvent'
import overridable from '../../utils/overridable'
import PageWrapper from '../PageWrapper'
import redirectIfCartIsEmpty from '../redirectIfCartIsEmpty/redirectIfCartIsEmpty'
import useCheckoutGoogleTagManager from '../useCheckoutGoogleTagManager'

const HooksWrapper = () => {
    useCheckoutGoogleTagManager(1)
    useStockCheck()
    return null
}

const StyledContainer = styled.div`
    position: relative;
`

export const Wrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding-top: 60px;
    padding-bottom: 100px;
    gap: 50px;
    @media ${minWidth('lg')} {
        position: relative;
        padding-top: 100px;
        flex-flow: row nowrap;
        justify-content: center;
        max-width: 880px;
        margin: 0 auto;
        min-height: 80vh;
        gap: 0;
    }
`

export const FormWrapper = styled.div`
    margin: 0;
    @media ${minWidth('lg')} {
        padding: 0 3em 0 0;
        flex: 1;
    }
`

const LoginPage = () => {
    const history = useHistory()
    // const { email, loading } = useCartItems()
    const { data, loading } = useCart({ fetchPolicy: 'cache-first' }, true)
    const email = data?.email

    const pushBeginCheckout = useBeginCheckoutEvent()
    const isLoggedIn = useIsLoggedIn()
    const isLoginPending = useReactiveVar(isLoggingIn)

    const hasPrivilegesEnabled = useHasPriviligesEnabled()

    useLayoutEffect(() => {
        if (isLoginPending || loading) {
            return
        }
        pushBeginCheckout()
        if (isLoggedIn || email) {
            history.replace(paths.checkoutDelivery)
        }
    }, [email, history, isLoggedIn, isLoginPending, pushBeginCheckout, loading])

    return (
        !loading && (
            <PageWrapper
                contentClassName={
                    hasPrivilegesEnabled ? styles.priviligesBackground : ''
                }
            >
                <Helmet>
                    <title>
                        {t({
                            id: 'checkout.LoginPage.pageTitle',
                            message: `Login`,
                        })}
                    </title>
                </Helmet>
                <HooksWrapper />

                <Container>
                    <StyledContainer>
                        <Wrapper>
                            <FormWrapper>
                                <CheckoutAccessForm />
                            </FormWrapper>
                            <AccountUsps />
                        </Wrapper>
                    </StyledContainer>
                </Container>
            </PageWrapper>
        )
    )
}

export default compose(
    redirectIfCartIsEmpty,
    overridable('CheckoutLoginPage'),
)(LoginPage) as React.ComponentType
