import cx from 'classnames'
import * as React from 'react'

import styles from './Carousel.module.scss'
import Carousel from '../behaviour/Carousel'

interface Props {
    children: React.ReactNode
    className?: string
}

const TweakwiseRecommendationsCarousel = ({ children, className }: Props) => (
    <Carousel showArrows className={cx(styles.carousel, className)}>
        {children}
    </Carousel>
)

export default TweakwiseRecommendationsCarousel
