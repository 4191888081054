import Component, { Props } from './Component'
import { useCoreConfigPaymentMethods } from '../../coreConfig.query'
import { CoreConfigData } from '../../graphql/schema.generated'

const FooterPaymentMethodsContainer = (
    props: Omit<Props, 'paymentMethods'> & { size?: 'large' },
) => {
    const data: CoreConfigData[] | undefined = useCoreConfigPaymentMethods()

    if (!data) {
        return null
    }
    const paymentMethods = data
        .map((method) => method.value ?? '')
        ?.filter(Boolean)

    return <Component paymentMethods={paymentMethods} {...props} />
}

export default FooterPaymentMethodsContainer
