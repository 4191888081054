import { makeVar } from '@apollo/client'
import Cookies from 'universal-cookie'

import loginTokenVar from '@emico/login-token'

import {
    CustomerAccountManagementV1ActivatePutBody,
    ThunkResult,
} from '../../types'
import { isEmpty } from '../../utils/validateRequired'

const HIGHSTREET_TOKEN_COOKIE_NAME = 'ns_access_token'

export const activate = (
    token: string,
    email: string,
    password: string,
): ThunkResult<Promise<boolean>> =>
    async function thunk(dispatch, getState, { request }): Promise<boolean> {
        if (isEmpty(email)) {
            throw new Error('email is required for activating an account.')
        }
        if (isEmpty(password)) {
            throw new Error('password is required for activating an account.')
        }

        const body: CustomerAccountManagementV1ActivatePutBody & {
            password: string
        } = {
            confirmationKey: token,
            password,
        }

        return request(`/rest/V1/customers/${email}/activate-user`, {
            method: 'PUT',
            body: JSON.stringify(body),
            ignoreAuthFailure: true,
        })
    }

export const getHighStreetToken = (): string | undefined => {
    const cookies = new Cookies()
    const token = cookies.get(HIGHSTREET_TOKEN_COOKIE_NAME) as
        | string
        | undefined

    return token || undefined
}

// We use local storage for storing the user token since local storage is
// synched across tabs. This means that if one tab is logged in, all tabs will
// be logged in. More importantly if one tab logs out then all tabs log out.
// TODO: Stop using local storage; https://support.emico.nl/issues/110688. We
//  can still use local storage to sign all tabs out simultaneously by simply
//  setting an isSignedIn boolean and listening to that change.
export const getToken = (): string | null => loginTokenVar() ?? null

export const setToken = (token: string): void => {
    loginTokenVar(token)
}

// TODO: Get correct token expire time from API
export const clearToken = (): void => {
    loginTokenVar(undefined)
}

export const isSignedInVar = makeVar(false)
/**
 * @deprecated Use the useIsLoggedIn hook from @emico-hooks/login-token
 */
export const isSignedIn = (): boolean => isSignedInVar()
