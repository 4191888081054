import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import PriviligesLogoIcon from './core/PriviligesLogoIcon'
import MemberUsps from './MemberUsps'
import theme from './theme'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'
import useMemberUspData from './useMemberUspData'

const Wrapper = styled.div`
    @media ${minWidth('lg')} {
        flex: 1;
        position: relative;
        padding-left: 3em;
        margin-top: -22px; /* offset the MemberUsp box so headings align nicely */

        &::before {
            display: block;
            position: absolute;
            width: 1px;
            height: calc(100% + 4em);
            top: -2em;
            left: 0;
            content: '';
            background: ${theme.colors.grayLighter};
        }
    }
`

const AccountUsps = () => {
    const { memberUspData, loading } = useMemberUspData('login')
    const {
        heading,
        headingVariant,
        usps,
        buttonText,
        buttonUrl,
        showPriviligesLogo,
    } = memberUspData || {}

    if (loading || !heading || !usps || usps.length === 0) {
        return <Wrapper />
    }

    return (
        <Wrapper>
            <MemberUsps
                heading={
                    <>
                        {showPriviligesLogo && <PriviligesLogoIcon />}
                        <Heading
                            variant={
                                headingVariant === HeadingVariant.h1
                                    ? HeadingVariant.h1
                                    : HeadingVariant.h2
                            }
                            element="h2"
                        >
                            {heading}
                        </Heading>
                    </>
                }
                usps={usps}
                buttonText={buttonText || undefined}
                buttonUrl={buttonUrl || undefined}
            />
        </Wrapper>
    )
}

export default AccountUsps
