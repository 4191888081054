import { PersonalizedAccountHero } from 'components/src/AccountHero'
import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import AccountIcon from './components/AccountIcon'
import BackIcon from './components/BackIcon'
import BackToTopIcon from './components/BackToTopIcon'
import Badge from './components/Badge'
import BookmarkIcon, { BookmarkFilledIcon } from './components/BookmarkIcon'
import CartEmptyIcon from './components/CartEmptyIcon'
import CartIcon from './components/CartIcon'
import CheckIcon from './components/CheckIcon'
import CloseIcon from './components/CloseIcon'
import DropdownIcon from './components/DropdownIcon'
import InfoIcon from './components/InfoIcon'
import MemberCardBody from './components/MemberCardBody'
import NewsletterSubmit from './components/NewsletterSubmit'
import PlusIcon from './components/PlusIcon'
import SearchIcon from './components/SearchIcon'
import StoresIcon from './components/StoresIcon'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import UspCheckIcon from './components/UspCheckIcon'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    InfoIcon,
    AccountIcon,
    BackIcon,
    BackToTopIcon,
    BookmarkIcon,
    BookmarkFilledIcon,
    CartEmptyIcon,
    CartIcon,
    CheckIcon,
    CloseIcon,
    DropdownIcon,
    PlusIcon,
    SearchIcon,
    StoresIcon,
    SuccessPageBackgroundImages,
    UspCheckIcon,
    ThemeOverrides,
    NewsletterSubmit,
    Badge,
    AccountHero: PersonalizedAccountHero,
    MemberCardBody,
}

export default componentsConfig
