import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import * as React from 'react'

import styles from './ConfigurableAttributeTiles.module.scss'
import RadioButton from '../../../input/RadioButton'
import { ConfigurableAttribute } from '../../common/MinimalConfigurableProductInfo.fragment'
import {
    getVariant,
    hasLimitedStock,
    isOutOfStock,
} from '../ConfigurableAttributesField/helpers'
import { ConfigurableProduct } from '../ConfigurableProduct'

interface OwnProps {
    attribute: ConfigurableAttribute
    product: ConfigurableProduct
    onChange(newValue?: string): void
    value: string
}

export type Props = OwnProps

const ConfigurableAttributeTiles = ({
    attribute,
    product,
    onChange,
    value,
    ...others
}: Props) => (
    <div
        className={styles.default}
        {...others}
        data-testid={`product.option.${attribute.attributeCode}`}
    >
        {attribute.values.map((attributeValue, index) => {
            const variant = getVariant(product.variants, [attributeValue])
            const stockStatus = variant && variant.product
            const outOfStock = isOutOfStock(variant)
            const limitedStock = hasLimitedStock(variant)

            const quantity = stockStatus && stockStatus.onlyXLeftInStock
            const title = outOfStock
                ? t({
                      id: 'catalog.configurableAttributeTiles.outOfStock',
                      message: `This size is out of stock.`,
                  })
                : limitedStock
                  ? t({
                        id: 'core.configurableAttributeTiles.limitedStock',
                        message: `There are only ${quantity} left in this size.`,
                    })
                  : undefined

            // Deselect selected value when it is out of stock
            if (value === attributeValue.uid && outOfStock) {
                onChange()
            }

            return attribute.values.length > 1 ? (
                <RadioButton<React.InputHTMLAttributes<HTMLInputElement>>
                    key={`${index}-${String(attributeValue.uid)}`}
                    value={String(attributeValue.uid)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            onChange(attributeValue.uid)
                        }
                    }}
                    checked={value === attributeValue.uid && !outOfStock}
                    className={styles.radioButton}
                    disabled={outOfStock}
                    title={title}
                    variant="primary"
                    data-testid={`product.option.${attribute.attributeCode}.value.${attributeValue.label}`}
                >
                    {attributeValue.storeLabel}
                </RadioButton>
            ) : (
                <span className={styles.attributeValueLabel} key={index}>
                    <Trans id="cart.addToCartForm.oneSize">One size</Trans>
                </span>
            )
        })}
    </div>
)

export default ConfigurableAttributeTiles
