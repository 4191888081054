import { ApolloClient, gql, QueryHookOptions, useQuery } from '@apollo/client'
import {
    getCustomerTokenHeaderObject,
    useAuthorizationContext,
    useIsLoggedIn,
    useLoginTokenContext,
} from '@emico-hooks/login-token'
import { getMagentoErrorCategory } from '@emico-utils/magento-graphql-error-categories'

import CONFIGURABLE_PRODUCT_INFO_FRAGMENT from '../catalog/common/MinimalConfigurableProductInfo.fragment'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import {
    Customer as GeneratedCustomer,
    SalesLimitsStatus,
    SrsVoucher,
} from '../graphql/schema.generated'
import { personalShopperBoxProductInfoFragment } from '../ProductCardFragment'
import withSentry from '../utils/withSentry'
import { wishlistFragment } from '../wishlist/GetWishlist.query'

export { SalesLimitsStatus }

export const fragments = {
    customerFields: gql`
        fragment CustomerFields on Customer {
            firstname
            lastname
            dateOfBirth
            email
            loyaltyPoints
            personalizedImage
            shoppingAllowed
            salesLimitsInfo {
                status
                redirectUrl
                ordersRemaining
            }
            wishlists {
                ...WishlistFields
            }
            groupId
        }
        ${wishlistFragment}
    `,
    personalShopperFields: gql`
        fragment PersonalShopperFields on Customer {
            personalShopperBoxes {
                items {
                    title
                    description
                    expirationDate
                    items {
                        description
                        product {
                            ...PersonalShopperBoxProductInfo
                            ...ConfigurableProductInfo
                        }
                        productSku
                        qty
                    }
                }
            }
        }
        ${personalShopperBoxProductInfoFragment}
        ${CONFIGURABLE_PRODUCT_INFO_FRAGMENT}
    `,
}

export const query = gql`
    query GetUserDetails {
        customer {
            ...CustomerFields
            srsId
        }
    }
    ${fragments.customerFields}
`

const personalShopperQuery = gql`
    query GetPSUserDetails {
        customer {
            ...PersonalShopperFields
        }
    }
    ${fragments.personalShopperFields}
`

export type Customer = Override<
    Pick<
        GeneratedCustomer,
        | 'firstname'
        | 'lastname'
        | 'dateOfBirth'
        | 'loyaltyPoints'
        | 'shoppingAllowed'
        | 'salesLimitsInfo'
        | 'email'
        | 'wishlists'
        | 'personalizedImage'
        | 'groupId'
        | 'srsId'
    >,
    {
        email: string
    }
>

export interface CustomerData {
    customer: Customer
}

export interface PersonalShopperData {
    customer: {
        personalShopperBoxes: {
            items?: Array<{
                title?: string
                description?: string
                items?: Array<{
                    product: ConfigurableProduct
                    description?: string
                    /** SKU (in case of a Configurable Product this is the preferred SKU */
                    productSku: string
                }>
            }>
        }
    }
}

export interface SrsVoucherData {
    customer: { srsVouchers?: SrsVoucher[] }
}

export const getCustomer = async (
    client: ApolloClient<unknown>,
    customerToken: string,
) =>
    client.query<CustomerData>({
        query,
        context: {
            skipAuthRedirect: true,
            ...getCustomerTokenHeaderObject(customerToken),
        },
    })

export const useGetCustomer = (options?: QueryHookOptions) => {
    const authorizationContext = useAuthorizationContext()
    const isLoggedIn = useIsLoggedIn()
    const { data, error, ...rest } = useQuery<CustomerData>(query, {
        ...options,
        skip: !isLoggedIn,
        context: authorizationContext,
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
    })

    const tokenContext = useLoginTokenContext()

    if (
        error &&
        (getMagentoErrorCategory(error) === 'graphql-authorization' ||
            getMagentoErrorCategory(error) === 'graphql-authentication')
    ) {
        tokenContext(undefined)
    }

    return {
        data: data && data.customer ? data.customer : undefined,
        ...rest,
    }
}

export const useCustomerLoyaltyPoints = () => {
    const { data, ...rest } = useGetCustomer()
    return {
        data: data && data.loyaltyPoints ? data.loyaltyPoints : undefined,
        ...rest,
    }
}
export const getPersonalShopper = async (
    client: ApolloClient<unknown>,
    token: string,
) =>
    client.query<PersonalShopperData>({
        query: personalShopperQuery,
        fetchPolicy: 'cache-first',
        context: {
            skipAuthRedirect: true,
            ...getCustomerTokenHeaderObject(token),
        },
    })

export const usePersonalShopper = (options?: QueryHookOptions) => {
    const authorizationContext = useAuthorizationContext()
    const { data, error, ...rest } = useQuery<PersonalShopperData>(
        personalShopperQuery,
        {
            ...options,
            context: authorizationContext,
        },
    )

    if (error) {
        // When an error occurs log it and then continue with an empty dataset.
        // We don't want to block the UI
        withSentry((Sentry) => {
            Sentry.captureException(error)
        })

        return {
            ...rest,
            data: [],
        }
    }

    return {
        ...rest,
        data: data?.customer?.personalShopperBoxes?.items || [],
    }
}
