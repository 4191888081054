import cx from 'classnames'
import { FieldProps, FormValues as InformedFormValues } from 'informed'
import * as React from 'react'

import styles from './Input.module.scss'
import useInformedInput from './useInformedInput'
import ErrorIndicator from '../ErrorIndicator'
import InputIndicatorTransition from '../InputIndicatorTransition'
import ValidIndicator from '../ValidIndicator'

interface OwnProps<InputFormValue> {
    /** Custom input styling hook */
    inputClassName?: string
    errorIndicatorClassName?: string
    validIndicatorClassName?: string
    withValidationIndicators?: boolean

    onInitialize?: (value: InputFormValue) => void
}

export type Props<
    FormValue,
    FormValues extends InformedFormValues = Record<never, never>,
> = OwnProps<FormValue> &
    FieldProps<FormValue, FormValues> & {
        // This prop is missing from informed's type defs. See: https://github.com/joepuzzo/informed/pull/241
        keepState?: boolean
        serializeValue?(value: FormValue): unknown
        deserializeValue?(value: unknown): FormValue | undefined
    }

const Input = <
    FormValue,
    FormValues extends InformedFormValues = Record<never, never>,
>({
    className,
    inputClassName,
    errorIndicatorClassName,
    validIndicatorClassName,
    validateOnChange = true,
    withValidationIndicators = true,
    field,
    forwardedRef,
    children,
    ...otherProps
}: Props<FormValue, FormValues>) => {
    const {
        render,
        hasError,
        isValid,
        inputProps,
        isTouched,
        errorMessage,
        useChecked,
    } = useInformedInput<FormValue, FormValues>({
        ...otherProps,
        validateOnChange,
        field,
    })

    return render(
        <span className={cx(styles.base, className)}>
            <input
                {...inputProps}
                ref={forwardedRef}
                className={cx(styles.input, inputClassName, {
                    [styles.hasValue]: inputProps.value,
                })}
                {...(errorMessage &&
                    !isValid && {
                        'aria-invalid': true,
                        'aria-errormessage': `err-${field}`,
                    })}
            />

            {children}

            {withValidationIndicators && (
                <InputIndicatorTransition>
                    {isTouched && hasError && (
                        <ErrorIndicator
                            className={errorIndicatorClassName}
                            field={field}
                        >
                            {errorMessage}
                        </ErrorIndicator>
                    )}

                    {isValid && !useChecked && (
                        <ValidIndicator className={validIndicatorClassName} />
                    )}
                </InputIndicatorTransition>
            )}
        </span>,
    )
}

export default Input
