import cx from 'classnames'
import * as React from 'react'

import { ComponentSlider } from '@emico/component-slider'

import styles from './Carousel.module.scss'

type Props = {
    children: React.ReactNode
    className?: string
    slideGap?: number
    showArrows?: boolean
    onSlideChange?: (index: number) => void
}

function Carousel({
    children,
    className,
    showArrows,
    slideGap = 12,
    onSlideChange,
}: Props) {
    return (
        <ComponentSlider
            onSlideChange={onSlideChange}
            className={cx(styles.slider, className)}
            arrows={showArrows}
            slideGap={slideGap}
            renderPrevArrow={({ disabled, handleClick }) => (
                <span
                    className={cx(
                        styles.sliderNavButtonWrapper,
                        styles.prev,
                        disabled ? styles.disabled : '',
                    )}
                    onClick={handleClick}
                />
            )}
            renderNextArrow={({ disabled, handleClick }) => (
                <span
                    className={cx(
                        styles.sliderNavButtonWrapper,
                        disabled ? styles.disabled : '',
                    )}
                    onClick={handleClick}
                />
            )}
        >
            {children}
        </ComponentSlider>
    )
}

export default Carousel
