import { Image } from '@emico-react/image'
import classNames from 'classnames'
import * as React from 'react'

import styles from './ImageSliderMobile.module.scss'
import Carousel from '../../../behaviour/Carousel'
import CloseIcon from '../../../core/CloseIcon'
import { MaveClip } from '../../../mave/MaveClip'
import { MediaGalleryEntry } from '../../../ProductCardFragment'
import makeMediaUrl, { MediaType } from '../../../utils/makeMediaUrl'

interface Props {
    images: MediaGalleryEntry[]
}

const ImageSliderMobile = ({ images }: Props) => {
    const zoomRef = React.useRef<HTMLDivElement | null>(null)
    const [zoomed, setZoomed] = React.useState<boolean>(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)

    const handleClick = (index: number) => {
        if (zoomRef.current) {
            const img = zoomRef.current.getElementsByTagName('img')?.[0]
            const { width: imageWidth } = img.getBoundingClientRect() ?? {}

            const { width: containerWidth } =
                zoomRef.current.getBoundingClientRect()
            const scrollLeft = (imageWidth - containerWidth) / 2
            zoomRef.current.scrollTo({ left: scrollLeft })
        }
        setCurrentIndex(index)
        setZoomed(true)
    }

    return (
        <>
            <div
                ref={zoomRef}
                className={classNames(
                    styles.zoomedImageContainer,
                    zoomed ? styles.zoomed : '',
                )}
                onClick={() => setZoomed(false)}
            >
                <Image
                    url={makeMediaUrl(
                        images[currentIndex].file,
                        MediaType.Product,
                    )}
                    width={1500}
                    alt=""
                    lazy={false}
                    className={styles.zoomedImage}
                />
            </div>

            <div
                className={classNames(
                    styles.slider,
                    zoomed ? styles.zoomed : '',
                )}
                style={{ position: 'relative' }}
            >
                <Carousel onSlideChange={(index) => setCurrentIndex(index)}>
                    {images.map((image, index) => {
                        const isVideo = image.mave !== null

                        return (
                            <div
                                key={image.file}
                                style={{ height: '100vh', width: '100vw' }}
                                onClick={() =>
                                    isVideo ? undefined : handleClick(index)
                                }
                            >
                                {isVideo ? (
                                    <MaveClip
                                        embed={image.mave?.id ?? ''}
                                        loop
                                    />
                                ) : (
                                    <Image
                                        url={makeMediaUrl(
                                            image.file,
                                            MediaType.Product,
                                        )}
                                        sizes={{
                                            '429': 752,
                                            '1': 428,
                                        }}
                                        alt={image.label ? image.label : ''}
                                        lazy={index !== 0}
                                    />
                                )}
                            </div>
                        )
                    })}
                </Carousel>
            </div>

            {!zoomed && (
                <div className={styles.sliderIndicatorContainer}>
                    <span
                        className={styles.sliderIndicator}
                        style={{
                            width: `${1 / (images.length / 100)}%`,
                            left: `${currentIndex / (images.length / 100)}%`,
                        }}
                    />
                </div>
            )}
            {zoomed && (
                <div
                    className={styles.closeZoomedContainer}
                    onClick={() => setZoomed(false)}
                >
                    <CloseIcon />
                </div>
            )}
        </>
    )
}

export default ImageSliderMobile
