import cx from 'classnames'
import * as React from 'react'

import styles from './PageWrapper.module.scss'
import Canonicals from '../../canonicals/Canonicals'
import FloatingContactUsButton from '../../core/FloatingContactUsButton'
import Footer from '../../core/Footer/Footer'
import isHighstreet from '../../isHighstreet'
import { pageTypeVar } from '../../pageTypeVar'
import usePageDataLayer from '../../utils/googleTagManager/usePageDataLayer'
import Header from '../Header'
import HeaderContainer from '../Header/HeaderContainer'

export type PageType =
    | 'cms'
    | 'product'
    | 'category'
    | 'user'
    | 'cart'
    | 'checkout'
    | 'wishlist'
    | 'N/A'

interface Props {
    pageType: PageType
    // A custom header if you want to override the default. Pass `null` to disable the header completely.
    header?: React.ReactNode
    // A custom footer if you want to override the default. Pass `null` to disable the footer completely.
    footer?: React.ReactNode
    floatingContactUsButton?: React.ReactNode
    children: React.ReactNode
    className?: string
    style?: React.CSSProperties | undefined
    headerClassName?: string
    contentClassName?: string
    hideStickyCtaFooter?: (value: boolean) => void
}
/**
 * The pageWrapper class and pageType classes are used as hooks within the application
 * to change styling based on page type.
 */
const PageWrapper = ({
    children,
    pageType,
    header,
    footer,
    floatingContactUsButton,
    className,
    style,
    headerClassName,
    contentClassName,
    hideStickyCtaFooter,
}: Props) => {
    const isMobileApp = isHighstreet()

    usePageDataLayer(pageType)
    pageTypeVar(pageType)

    const appHeader =
        isMobileApp || header === null
            ? null
            : header || (
                  <HeaderContainer>
                      <Header className={headerClassName} />
                  </HeaderContainer>
              )

    const appFooter =
        isMobileApp || footer === null
            ? null
            : footer || (
                  <div className={styles.footerContainer}>
                      <Footer hideStickyCtaFooter={hideStickyCtaFooter} />
                  </div>
              )

    return (
        <div
            className={cx(styles.default, 'pageWrapper', pageType, className)}
            style={style}
        >
            <Canonicals type={pageType} />

            {appHeader}
            <main
                className={cx(styles.content, contentClassName, 'topbarbanner')}
            >
                <div id="content">{children}</div>
            </main>

            {floatingContactUsButton !== undefined ? (
                floatingContactUsButton
            ) : floatingContactUsButton === null ? null : (
                <FloatingContactUsButton />
            )}

            {appFooter}
        </div>
    )
}

export default PageWrapper
