import { Image } from '@emico-react/image'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { CheckIcon } from '@emico/icons'

import optionStyles from './Option.module.scss'
import { MAX_COLS, OptionsType } from './Options'
import styles from './Options.module.scss'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'

export const DefaultOptions = ({ answers, isMultiSelect }: OptionsType) => {
    const { register } = useFormContext()
    const gridRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // desktop grid cols depends on the number of answers:
        // mobile: 2 cols always
        // desktop:
        const divider = Math.min(
            answers?.length ?? 0,
            MAX_COLS,
            answers?.length ?? 0 % MAX_COLS,
        )

        gridRef.current?.style.setProperty(
            '--num-cols',
            `${Math.max(divider, MAX_COLS / (divider || 1))}`,
        )
    }, [answers?.length])

    if (!answers) {
        return null
    }

    const answersWithPossibleColorCodes = answers.map((answer) => {
        const { answer: answerString } = answer
        const [label, color] = (answerString || '').split('_', 2)
        return { ...answer, colorCode: color ?? '', answer: label }
    })

    return (
        <div className={styles.grid} ref={gridRef}>
            {answersWithPossibleColorCodes?.map((answer) => (
                <label key={answer.answerId} className={optionStyles.option}>
                    <span className={optionStyles.selectedIndicator}>
                        <CheckIcon />
                    </span>
                    {answer?.imageUrl && (
                        <Image
                            url={answer.imageUrl ?? ''}
                            className={optionStyles.answerImage}
                            width={250}
                            lazy
                            alt={answer.answer ?? ''}
                        />
                    )}
                    <input
                        {...register?.('question')}
                        value={answer.answerId}
                        type={isMultiSelect ? 'checkbox' : 'radio'}
                    />

                    <div className={optionStyles.answerHeader}>
                        <Heading
                            color="light"
                            variant="h3"
                            element="h3"
                            className={classNames(
                                styles.header,
                                answer.colorCode && optionStyles.light,
                            )}
                        >
                            {answer?.answer}
                            {answer.colorCode && (
                                <div
                                    className={styles.colorBanner}
                                    style={{
                                        backgroundColor: answer.colorCode,
                                    }}
                                />
                            )}
                        </Heading>
                        <Text
                            className={optionStyles.answerCaption}
                            color="light"
                            size="small"
                        >
                            {answer?.caption}
                        </Text>
                    </div>
                </label>
            ))}
        </div>
    )
}
