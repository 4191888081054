import classNames from 'classnames'
import { ComponentProps, forwardRef } from 'react'

import {
    ArrowButton,
    NextArrow,
    SliderDots,
    SliderDotsProps,
} from '@emico/component-slider'

import styles from './ComponentSlider.module.scss'

const NewArrowButton = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof ArrowButton.original>
>(function NewArrowButton(props, ref) {
    return (
        <ArrowButton.original
            {...props}
            ref={ref}
            className={classNames(props.className, styles.button)}
        />
    )
})

const NewNextArrow = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof NextArrow.original>
>(function NewArrowButton(props, ref) {
    return (
        <NextArrow.original
            {...props}
            ref={ref}
            className={classNames(props.className, styles.next)}
        />
    )
})

export const NewSliderDots = function NewSliderDots(props: SliderDotsProps) {
    const dots = Array.from(Array(props.slides).keys())
    return (
        <div className={styles.dotsWrapper}>
            <div className={styles.dots}>
                {dots?.map((slide, idx) => (
                    <span
                        key={slide}
                        onClick={() => props.onDotClick(idx)}
                        className={classNames(
                            styles.dot,
                            props.currentSlide === idx ? styles.active : '',
                        )}
                    />
                ))}
            </div>
        </div>
    )
}

ArrowButton.override(NewArrowButton as unknown as typeof ArrowButton.original)
NextArrow.override(NewNextArrow as unknown as typeof NextArrow.original)
SliderDots.override(NewSliderDots as unknown as typeof SliderDots.original)
