import { ReactNode } from 'react'

import styles from './DynamicProductLabels.module.scss'
import { ProductLabel } from '../../graphql/schema.generated'

export const DynamicProductLabels = ({
    labels,
    children,
}: {
    labels: ProductLabel[]
    children?: ReactNode
}) => (
    <div className={styles.labels}>
        {children}
        {labels &&
            labels.length > 0 &&
            labels?.map((label) => (
                <span
                    key={label.text}
                    className={styles.label}
                    style={{
                        backgroundColor: label.backgroundColor,
                        color: label.textColor,
                    }}
                >
                    {label.text}
                </span>
            ))}
    </div>
)
