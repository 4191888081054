import { useIsLoggedIn } from '@emico-hooks/login-token'
import { push } from '@emico-utils/datalayer'
import { GtmEvent } from '@emico-utils/datalayer-templates'
import { globalWindow } from '@emico-utils/ssr-utils'
import { useCallback, useEffect, useState } from 'react'

import { StoreView, useActiveStoreView } from '@emico/storeviews'

import { getShopIdFromCookie } from '../../layout/Header/TopBar/ShopIdTopBarBanner'
import { pageTypeVar } from '../../pageTypeVar'
import { useSrsStore } from '../../storelocator/useSrsStore'
import { getBusinessByStoreView } from '../getBusinessByStoreView'

export const eventNameAffix = '_ga4'
export function eventAffix(name: string) {
    return `${name}${eventNameAffix}`
}

export function usePushEcommerceEvent(
    defaultEvents: GtmEvent[] = [],
    autoTrigger: boolean = true,
    pushOnlyOnce: boolean = true,
) {
    const shopId = getShopIdFromCookie()
    const { data: srsStore, loading: srsStoreLoading } = useSrsStore(shopId)
    const isLoggedIn = useIsLoggedIn()
    const activeStoreView = useActiveStoreView()

    const { language, name: businessName } =
        getBusinessByStoreView(activeStoreView)

    const [pushed, setPushed] = useState(false)

    const pushEvents = useCallback(
        (events: GtmEvent[] = defaultEvents) => {
            if ((pushed && pushOnlyOnce) || srsStoreLoading) {
                return
            }

            for (const event of events) {
                if (event.ecommerce === null) {
                    // Push event: { ecommerce: null }
                    push(event)
                    continue
                }

                const combinedEvent = {
                    ...event,
                    event:
                        typeof event.event === 'string'
                            ? eventAffix(event.event)
                            : event.event,

                    business_label: businessName,
                    page_language: language,
                    page_type: pageTypeVar(),
                    // This is redundant
                    page_url: globalWindow?.location.href,

                    shop_id: shopId,
                    shop_hit: shopId != null,
                    shop_name:
                        shopId != null
                            ? `${srsStore?.name} - ${srsStore?.city}`
                            : undefined,

                    use_logged_in: isLoggedIn,
                }

                push(combinedEvent)
            }
            setPushed(true)
        },
        [
            businessName,
            defaultEvents,
            isLoggedIn,
            language,
            pushOnlyOnce,
            pushed,
            shopId,
            srsStore?.city,
            srsStore?.name,
            srsStoreLoading,
        ],
    )

    useEffect(() => {
        if (!autoTrigger) {
            return
        }

        pushEvents()
    }, [autoTrigger, pushEvents])

    return srsStoreLoading ? null : pushEvents
}
