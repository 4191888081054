import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'

import styles from './CartItemInfoContainer.module.scss'
import { LazyProductPage } from '../../chunks'
import { WISHLIST_DISABLED } from '../../constants'
import BinIcon from '../../core/BinIcon'
import { Responsive } from '../../core/Responsive'
import Button from '../../input/Button'
import Icon from '../../media/Icon'
import Link from '../../navigation/Link'
import Text from '../../typography/Text'
import overridable from '../../utils/overridable'
import WishlistButton from '../../wishlist/WishlistButton'
import CartItemConfiguration from '../CartItemConfiguration'
import { getConfigurableAttributeValuesFromCartItemProduct } from '../getConfigurableAttributeValuesFromCartItemProduct'

export interface Props {
    cartItem: CartItemFragment
    totals?: React.ReactNode
    handleRemoveItem?(): Promise<void>
    handleMoveToWishlist?(): Promise<void>
    disabled?: boolean
}

const CartItemInfoContainer = ({
    cartItem,
    totals,
    handleRemoveItem,
    handleMoveToWishlist,
    disabled,
}: Props) => {
    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    const { product } = cartItem

    if (!product) {
        return null
    }

    return (
        <div className={styles.infoContainer}>
            <div className={styles.header}>
                <div className={styles.info}>
                    <Text as="h1" color="dark">
                        <Link
                            to={`/${product.urlKey}`}
                            name={product.name}
                            category="cart.cartPage.cartItem"
                            onMouseEnter={handleMouseEnter}
                            variant="dark"
                        >
                            {product.name}
                        </Link>
                    </Text>
                    {product.sku}
                </div>
                <div className={styles.priceContainer}>
                    <Responsive md up>
                        {totals}
                    </Responsive>
                    <Responsive sm down>
                        <Button
                            variant="link"
                            onClick={handleRemoveItem}
                            name="Remove item from cart"
                            category="cart.removeFromCartButton"
                        >
                            <Icon
                                component={BinIcon}
                                title={t({
                                    id: 'cart.cartItem.removeIconLabel',
                                    message: `Remove item from cart`,
                                })}
                                className={styles.removeIcon}
                            />
                        </Button>
                    </Responsive>
                </div>
            </div>
            <div className={styles.configuration}>
                <CartItemConfiguration cartItem={cartItem} product={product} />
            </div>
            <div className={styles.actions}>
                <Responsive md up>
                    <Button
                        variant="link"
                        onClick={handleRemoveItem}
                        name="Remove item from cart"
                        category="cart.removeFromCartButton"
                        data-testid="cart.item.remove"
                        className={styles.removeButton}
                    >
                        <Trans id="cart.cartItem.removeButtonLabelDesktop">
                            remove item from cart
                        </Trans>
                    </Button>
                    {!WISHLIST_DISABLED && (
                        <>
                            |
                            <div className={styles.bookmark}>
                                <WishlistButton
                                    value={
                                        getConfigurableAttributeValuesFromCartItemProduct(
                                            cartItem,
                                        ) ?? undefined
                                    }
                                    className={styles.wishlistButton}
                                    product={product}
                                    optimistic={false}
                                    onAddToWishlist={handleMoveToWishlist}
                                    render={(isActive) => (
                                        <>
                                            {isActive ? (
                                                <Trans id="cart.cartItem.removeFromWishlistButtonLabel">
                                                    remove from wishlist
                                                </Trans>
                                            ) : (
                                                <Trans id="cart.cartItem.moveToWishlistButtonLabel">
                                                    move to wishlist
                                                </Trans>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </>
                    )}
                </Responsive>
                <Responsive sm down>
                    {totals}
                </Responsive>
            </div>
        </div>
    )
}

export default overridable('CartItemInfoContainer')(CartItemInfoContainer)
