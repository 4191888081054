import { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useCoreConfigValue } from '../coreConfig.query'
import { CustomerOrder } from '../graphql/schema.generated'

export function TrustedShopsScript({
    yOffset = '0',
    variant = 'default',
    customElementId = '',
    trustcardDirection = '',
    customBadgeWidth = '40',
    customBadgeHeight = '100',
    disableResponsive = 'false',
    disableTrustbadge = 'false',
}: TrustedShopsConfig) {
    const { value: id } = useCoreConfigValue('trustedshops/general/ts_id')

    useEffect(() => {
        window._tsConfig = {
            yOffset,
            variant,
            customElementId,
            trustcardDirection,
            customBadgeWidth,
            customBadgeHeight,
            disableResponsive,
            disableTrustbadge,
        }
    }, [
        customBadgeHeight,
        customBadgeWidth,
        customElementId,
        disableResponsive,
        disableTrustbadge,
        trustcardDirection,
        variant,
        yOffset,
    ])

    if (!id) {
        return <></>
    }

    return (
        <Helmet>
            <script
                id="trustedShopsScript"
                key="trustedShopsScript"
                async
                defer
                src={`https://widgets.trustedshops.com/js/${id}.js`}
            ></script>
        </Helmet>
    )
}

interface TrustCardSettingsProps {
    order: CustomerOrder
}

export const TrustCardSettings = ({ order }: TrustCardSettingsProps) => {
    const { value: enabled } = useCoreConfigValue(
        'trustedshops/checkout_optin/enabled',
    )
    const isEnabled = enabled === '1'

    // Trigger reinitialisation after first render
    // This will show the trusted shops dialog.
    useEffect(() => {
        if (isEnabled && window.trustbadge) {
            try {
                window.trustbadge.reInitialize()
            } catch {
                // too bad, no-op
            }
        }
    }, [isEnabled])

    return isEnabled && window.trustbadge ? (
        <div id="trustedShopsCheckout" style={{ display: 'none' }}>
            <span id="tsCheckoutOrderNr">{order?.number}</span>
            <span id="tsCheckoutBuyerEmail">{order.email}</span>
            <span id="tsCheckoutOrderAmount">
                {order.total?.grandTotal.value}
            </span>
            <span id="tsCheckoutOrderCurrency">
                {order.total?.grandTotal?.currency}
            </span>
            <span id="tsCheckoutOrderPaymentType">
                {order.payment?.methodTitle ?? ''}
            </span>
            <span id="tsCheckoutOrderEstDeliveryDate" />
        </div>
    ) : null
}

interface TrustBadgeProps {
    className?: string
}

const TrustBadgeComp = ({ className }: TrustBadgeProps) => {
    const { value: enabled } = useCoreConfigValue(
        'trustedshops/trustbadge/enabled',
    )
    const isEnabled = enabled === '1' || true

    useEffect(() => {
        if (isEnabled && window.trustbadge) {
            try {
                window.trustbadge.reInitialize()
            } catch {
                // too bad, no-op
            }
        }

        // TODO: This is flaky
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled, window.trustbadge])

    return (
        <div
            id="tsBadge"
            className={className}
            style={{
                width: `100px`,
                height: `40px`,
                display: isEnabled ? 'initial' : 'none',
            }}
        />
    )
}

export const TrustBadge = memo(TrustBadgeComp)
